import styled from '@emotion/styled';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { EquipmentData } from '@showatt/core';
import { SnackbarProvider } from 'notistack';
import { useState } from 'react';
import { useQuery } from 'react-query';
import useZone from './context/useZone';
import EquipmentTable from './EquipmentTable';
import getEquipments from './getEquipments';

const Container = styled('div')`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const AddContainer = styled('div')`
  width: 100%;
  display: flex;
  gap: 16px;
`;

const NoEquipment = styled('div')`
  margin-top: 16px;
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export enum EquipmentEnum {
  light = 'light',
  video = 'video',
  prod = 'prod',
  rigg = 'rigg',
  amp = 'amp',
}

interface EquipmentProps {
  equipmentType: keyof typeof EquipmentEnum;
}

const getOptions = (option: EquipmentData) => {
  switch (option.equipmentType) {
    case 'amp':
    case 'light':
      return `${option.marque} / ${option.type} / ${option.ref}`;
    case 'rigg':
    case 'video':
    case 'prod':
      return `${option.marque} ${option.ref}`;
    default:
      return '';
  }
};

const renderOptions = (option: EquipmentData) => {
  switch (option.equipmentType) {
    case 'amp':
    case 'light':
      return `${option.type} / ${option.ref}`;
    case 'rigg':
    case 'video':
    case 'prod':
      return `${option.ref}`;
    default:
      return ``;
  }
};

const Equipment = ({ equipmentType }: EquipmentProps) => {
  const [value, setValue] = useState<EquipmentData | null>(null);
  const { data } = useQuery('equipments', () => getEquipments());

  const { zone, addEquipment } = useZone();
  const [inputValue, setInputValue] = useState('');

  const handleAddEquipment = async (newValue: EquipmentData  | null) => {
    if (!newValue) {
      // enqueueSnackbar("Sélectionnez un matériel pour l'ajouter à la liste.", {
      //   variant: 'error',
      //   autoHideDuration: 3000,
      // });
      return;
    }
    setValue(newValue);
    addEquipment(newValue, equipmentType);
  };
  if (!zone || !data) return null;

  const equipmentDb: EquipmentData[] = data[equipmentType];

  if (equipmentDb.length === 0) {
    return (
      <NoEquipment>
        <p className='h6b'>Coming soon</p>
      </NoEquipment>
    );
  }

  return (
    <Container>
      <SnackbarProvider />
      <AddContainer>
        <Autocomplete
          id="grouped-demo"
          options={equipmentDb.sort(
            (a, b) => -b.marque.localeCompare(a.marque),
          )}
          value={value}
          onChange={(event: any, newValue: EquipmentData | null) => {
            handleAddEquipment(newValue);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          groupBy={(option) => option.marque}
          getOptionLabel={(option: EquipmentData) => getOptions(option)}
          renderOption={(props, option, state) => (
            <li {...props}>{renderOptions(option)}</li>
          )}
          sx={{ flex: '1' }}
          renderInput={(params) => (
            <TextField {...params} label="Sélection Matériel" />
          )}
        />
        {/* <Button
          onClick={() => handleAddEquipment(value)}
          variant="contained"
          color="primary"
          startIcon={<Add />}
        >
          Ajouter matériel
        </Button> */}
      </AddContainer>
      <EquipmentTable equipmentType={equipmentType} />
    </Container>
  );
};

export default Equipment;
