import { useParams } from 'react-router-dom';
import { ResultDispatch } from '../results/Results';
import Block, { TitleBlock } from '../ui/Block';
import { PageContainer } from '../ui/Container';
import useReport from './context/useReport';

const Report = () => {
  const { reportId } = useParams();
  const { report } = useReport(reportId);

  if (!report) {
    return null;
  }

  return (
    <PageContainer>
      <TitleBlock background="var(--reports-bg)">
        <h3>{report.name}</h3>
        <p>Découvrez votre rapport d'impact ci-dessous</p>
      </TitleBlock>
      <Block>
        {report.results.map((result, index) => (
          <ResultDispatch result={result} key={index} />
        ))}
      </Block>
    </PageContainer>
  );
};

export default Report;
