import styled from '@emotion/styled';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { ReactNode, useState } from 'react';

const Container = styled.div`
  width: 100%;
  background-color: var(--backgroundColor);
  display: flex;
  gap: 16px;
  padding: 0px 16px;
`;

interface LeftColumnProps {
  showRightColumn: boolean;
  previewRightColumn: boolean;
}

const LeftColumn = styled.div`
  width: ${({ showRightColumn, previewRightColumn }: LeftColumnProps) =>
    showRightColumn
      ? 'min(65%, calc(100% - 400px))'
      : previewRightColumn
      ? 'calc(100% - 200px)'
      : '100%'};
  min-height: calc(100vh - 64px);
  border-radius: 8px;
  position: sticky;
  top: 64px;
  transition: width 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
`;

interface RightColumnProps {
  showRightColumn: boolean;
  previewRightColumn: boolean;
  hovered: boolean;
}

const RightColumn = styled.div`
  position: relative;
  width: ${({ showRightColumn, previewRightColumn }: RightColumnProps) =>
    showRightColumn
      ? 'max(35%, 400px)'
      : previewRightColumn
      ? '200px'
      : '51px'};
  opacity: ${({ previewRightColumn }: RightColumnProps) =>
    previewRightColumn ? '0.3' : '1'};
  height: calc(100vh - 96px);
  position: sticky;
  top: 80px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  transition: width 0.3s ease-in-out;
  cursor: ${({ previewRightColumn }: RightColumnProps) =>
    previewRightColumn ? 'pointer' : 'auto'};
`;

// HeaderContainer is necessary to mask the parameters when scrolling
const HeaderContainer = styled.div`
  background-color: var(--backgroundColor);
  padding-top: 16px;
  position: sticky;
  top: 64px;
  z-index: 120;
`;

const MainContainer = styled.div`
  padding: 0;
  background-color: var(--backgroundColor);
  flex: 1;
`;

interface IconButtonContainerProps {
  showIcon: boolean;
}

const IconButtonContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 110;
  display: ${({ showIcon }: IconButtonContainerProps) =>
    showIcon ? 'block' : 'none'};
`;

interface CalculatorLayoutProps {
  header: ReactNode;
  content: ReactNode;
  results: ReactNode;
  useLocalStorage: boolean;
}

const CalculatorLayout = ({
  header,
  content,
  results,
  useLocalStorage,
}: CalculatorLayoutProps) => {
  const [showRightColumn, setShowRightColumn] = useState(() => {
    // Try to get the value from localStorage, if it exists. Otherwise, default to true.
    const localStorageValue = localStorage.getItem('showRightColumn');
    return useLocalStorage && localStorageValue
      ? JSON.parse(localStorageValue)
      : true;
  });
  const [hoveredRight, setHoveredRight] = useState(false);
  const [preventPreview, setPreventPreview] = useState(false);

  const toggleRightColumn = () => {
    const newShowRightColumn = !showRightColumn;
    setShowRightColumn(newShowRightColumn);
    // Update localStorage with the new value.
    if (useLocalStorage) {
      localStorage.setItem(
        'showRightColumn',
        JSON.stringify(newShowRightColumn),
      );
    }
    if (!newShowRightColumn) {
      setPreventPreview(true);
      setTimeout(() => {
        setPreventPreview(false);
      }, 1000); // Delay in milliseconds (1 second)
    }
  };

  const handleRightColumnClick = () => {
    if (!showRightColumn) setShowRightColumn(true);
  };

  const handleMouseEnter = () => {
    setHoveredRight(true);
  };

  const handleMouseLeave = () => {
    setHoveredRight(false);
  };

  const hovered = hoveredRight;
  const showIcon = showRightColumn ? hovered : true;
  const previewRightColumn = !showRightColumn && hoveredRight;

  return (
    <Container>
      <LeftColumn
        showRightColumn={showRightColumn}
        previewRightColumn={!preventPreview && previewRightColumn}
      >
        <HeaderContainer>{header}</HeaderContainer>
        <MainContainer>{content}</MainContainer>
      </LeftColumn>
      <RightColumn
        showRightColumn={showRightColumn}
        previewRightColumn={!preventPreview && previewRightColumn}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        hovered={hovered}
        onClick={handleRightColumnClick}
      >
        {(showRightColumn || (!preventPreview && previewRightColumn)) &&
          results}
        <IconButtonContainer showIcon={showIcon}>
          <IconButton
            sx={{
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
            onClick={toggleRightColumn}
          >
            <Tooltip
              title={
                showRightColumn
                  ? 'Cacher les résultats'
                  : 'Afficher les résultats'
              }
            >
              {showRightColumn ? (
                <DoubleArrowIcon fontSize="large" />
              ) : (
                <KeyboardArrowLeftIcon fontSize="large" />
              )}
            </Tooltip>
          </IconButton>
        </IconButtonContainer>
      </RightColumn>
    </Container>
  );
};

export default CalculatorLayout;
