import {
  Amp,
  Bar1D,
  Equipment,
  EquipmentData,
  EquipmentDBProps,
  EquipmentList,
  GlobalScore,
  Indicator,
  Light,
  Phase,
  Pie1D,
  Prod,
  Rigg,
  Subtitle,
  Text,
  Title,
  Video,
  Zone,
  ZoneReco,
} from '@showatt/core';
import {
  electricitySourcesData,
  electricitySourceType,
  equipmentNames,
  equipmentTypeColors,
} from '../zone/config';
import {
  getEquipmentConsumption,
  getEquipmentPower,
  getMaxReduction,
  getPeakPower,
  getPowerPerEquipment,
  getPowerPerPhase,
} from './getZonesResults';

// TO DUPLICATE CLIENT SIDE IN GET SHOW RESULTS

const getConsumptionAndUnit = (consumption: number) => {
  if (consumption > 10000) {
    return {
      number: Math.round(consumption / 1000).toLocaleString('fr-FR'),
      unit: 'kWh',
    };
  }
  return {
    number: Math.round(consumption).toLocaleString('fr-FR'),
    unit: 'Wh',
  };
};

const getEmissionsAndUnit = (emissions: number) => {
  if (emissions > 10000) {
    return {
      number: Math.round(emissions / 1000).toLocaleString('fr-FR'),
      unit: 'kgCO2eq',
    };
  }
  return {
    number: Math.round(emissions).toLocaleString('fr-FR'),
    unit: 'gCO2eq',
  };
};

const getZoneComplete = (zone: Zone, db: EquipmentDBProps) => {
  const equipmentComplete = getEquipmentComplete(zone, db);

  let zoneComplete = {
    ...zone,
    equipment: equipmentComplete,
  };

  const zoneResults = getZoneResults(zoneComplete);

  return {
    ...zoneComplete,
    resultsSimple: zoneResults.resultsSimple,
    resultsComplete: zoneResults.resultsComplete,
  };
};

const getZoneResults = (zone: Zone) => {
  const { consumption, electricitySource } = zone;
  const emissions =
    consumption *
    electricitySourcesData[electricitySource as keyof electricitySourceType].FE;

  const flattenEquipments: Equipment[] = getFlattenEquipments(zone.equipment);

  const consumptionReduction = getMaxReduction(flattenEquipments);
  const emissionsReduction =
    consumptionReduction *
    electricitySourcesData[electricitySource as keyof electricitySourceType].FE;
  const reduction = Math.round((consumptionReduction / consumption) * 100);

  const peakPower = Math.round(getPeakPower(zone));

  /////////////////////////////
  // RESULTS SIMPLE
  /////////////////////////////

  const consumptionData = getConsumptionAndUnit(consumption);
  const emissionsData = getEmissionsAndUnit(emissions);
  const factor = consumption > 10000 ? 1000 : 1;

  const indicatorConsumption: Indicator = {
    type: 'indicator',
    title: 'Consommation',
    unit: consumptionData.unit,
    number: consumptionData.number,
    code: 'consumption',
    color: 'var(--color-primary-light)',
  };

  const indicatorEmission: Indicator = {
    type: 'indicator',
    title: 'Emissions',
    unit: emissionsData.unit,
    number: emissionsData.number,
    code: 'emissions',
    color: 'var(--color-primary-light)',
  };

  const indicatorReduction: Indicator = {
    type: 'indicator',
    unit: '%',
    title: 'Potentiel de réduction',
    number: `${Math.round(reduction).toLocaleString('fr-FR')}`,
    code: 'reduction',
    color: 'var(--color-primary)',
  };

  const pieConsumptionByPhase: Pie1D = {
    type: 'pie1D',
    scope: 'simple',
    code: 'truc',
    title: 'Consommation / phase',
    unit: factor === 1000 ? 'kWh' : 'Wh',
    subtitle:
      'Synthèse de la consommation électrique en Wh par phase dans la zone concernée',
    description:
      'Consommation électrique cumulée en Watts Heure, tous matériels confondus, répartis selon la temporalité définie lors de la configuration des phases de l’événement.',
    data: getConsumptionPerPhase(zone, factor),
  };

  const pieConsumptionByEquipmentType: Pie1D = {
    type: 'pie1D',
    scope: 'simple',
    code: 'truc',
    title: 'Consommation / équipement',
    unit: factor === 1000 ? 'kWh' : 'Wh',
    subtitle:
      'Synthèse de la consommation électrique en Wh répartie par type de matériel scénique',
    description:
      'Consommation électrique cumulée en Watts Heure en fonction des types d’équipement scénique, toute phase confondue.',
    data: getConsumptionPerEquipmentType(zone, factor),
  };

  const indicatorPeakPower: Indicator = {
    type: 'indicator',
    title: 'Totale',
    unit: 'W',
    number: `${Math.round(peakPower).toLocaleString('fr-FR')}`,
    code: 'peakPower',
    color: 'var(--color-primary-light)',
  };

  const barPowerByEquipmentType: Bar1D = {
    type: 'bar1D',
    scope: 'simple',
    code: 'barPower',
    title: "Puissance crête par type d'équipement",
    unit: 'W',
    ...getPowerPerEquipment(zone),
  };

  /////////////////////////////
  // RESULTS COMPLETE
  /////////////////////////////

  const titleZone: Title = {
    type: 'title',
    scope: 'complete',
    icon: zone.type,
    text: zone.name,
    code: zone.id,
  };

  const subtitleFootprint: Subtitle = {
    type: 'subtitle',
    scope: 'complete',
    text: 'Empreinte',
  };

  const consumptionGlobalScore: GlobalScore = {
    type: 'globalScore',
    scope: 'complete',
    title: 'Consommation totale de la zone',
    score: `${consumptionData.number} ${consumptionData.unit}`,
    text: '',
  };

  const emissionsGlobalScore: GlobalScore = {
    type: 'globalScore',
    scope: 'complete',
    title: 'Emissions de GES totales de la zone',
    score: `${emissionsData.number} ${emissionsData.unit}`,
    text: '',
  };

  const reductionGlobalScore: GlobalScore = {
    type: 'globalScore',
    scope: 'complete',
    title: 'Réductions potentielles de la consommation et des émissions',
    score: `${Math.round(reduction).toLocaleString('fr-FR')} %`,
    text: '',
  };

  const subtitleReco: Subtitle = {
    type: 'subtitle',
    scope: 'complete',
    text: 'Optimisation énergétique du matériel',
  };

  const consumptionReductionScore: GlobalScore = {
    type: 'globalScore',
    scope: 'complete',
    title: 'Réduction consommation potentielle',
    score: `${Math.round(consumptionReduction).toLocaleString('fr-FR')} Wh`,
    text: '',
  };

  const emissionsReductionScore: GlobalScore = {
    type: 'globalScore',
    scope: 'complete',
    title: 'Réduction émissions potentielle',
    score: `${Math.round(emissionsReduction).toLocaleString('fr-FR')} gCO2eq`,
    text: '',
  };

  const zoneRecommandations: ZoneReco = {
    type: 'zoneReco',
    scope: 'complete',
    equipment: zone.equipment,
    electricitySource: zone.electricitySource,
    zoneConsumption: zone.consumption,
  };

  const subtitlePeakPower: Subtitle = {
    type: 'subtitle',
    scope: 'complete',
    text: 'Puissance crête',
  };

  const peakPowerScore: GlobalScore = {
    type: 'globalScore',
    scope: 'complete',
    title: 'Puissance crête totale',
    score: `${peakPower.toLocaleString('fr-FR')} W`,
    text: '',
  };

  const barPowerByPhase: Bar1D = {
    type: 'bar1D',
    scope: 'complete',
    code: 'barPowerByPhase',
    title: 'Puissance par phase',
    unit: 'W',
    ...getPowerPerPhase(zone),
  };

  const subtitleRecoEnergy: Subtitle = {
    type: 'subtitle',
    scope: 'complete',
    text: 'Optimisation source énergétique',
  };

  const recoEnergy: Text = {
    type: 'text',
    scope: 'complete',
    textId: 'result.recoEnergy',
  };

  return {
    resultsSimple: {
      indicatorConsumption,
      indicatorEmission,
      indicatorReduction,
      pieConsumptionByPhase,
      pieConsumptionByEquipmentType,
      barPowerByEquipmentType,
      indicatorPeakPower,
    },
    resultsComplete: [
      titleZone,
      subtitleFootprint,
      consumptionGlobalScore,
      emissionsGlobalScore,
      reductionGlobalScore,
      { ...pieConsumptionByPhase, scope: 'complete' },
      { ...pieConsumptionByEquipmentType, scope: 'complete' },
      subtitlePeakPower,
      peakPowerScore,
      { ...barPowerByEquipmentType, scope: 'complete' },
      barPowerByPhase,
      subtitleReco,
      reductionGlobalScore,
      consumptionReductionScore,
      emissionsReductionScore,
      zoneRecommandations,
      subtitleRecoEnergy,
      recoEnergy,
    ],
  };
};

const getEquipmentComplete = (zone: Zone, db: EquipmentDBProps) => {
  let equipmentComplete: EquipmentList = {
    light: [] as Light[],
    rigg: [] as Rigg[],
    video: [] as Video[],
    amp: [] as Amp[],
    prod: [] as Prod[],
  };
  Object.keys(zone.equipment).forEach((equipmentType) => {
    const equipments = zone.equipment[equipmentType as keyof EquipmentList];
    const equipmentsComplete = equipments.map((equipment: Equipment) => {
      let substitutes: Equipment[] = [];
      let maxReduction = 0;
      equipment.isSubstitute = false;
      if (equipment.type === equipmentType) {
        const eqSubstitutesId = db[equipmentType].find(
          (data: EquipmentData) => data._id === equipment.data._id,
        )?.substitutesId;

        if (eqSubstitutesId && eqSubstitutesId.length > 0) {
          eqSubstitutesId.forEach((substituteId: string) => {
            const substituteData = db[equipmentType].find(
              (data: EquipmentData) => data._id === substituteId,
            );

            if (substituteData) {
              const eqPhases = zone.phases.filter((phase: Phase) =>
                equipment.phases.includes(phase.id),
              );
              const substitute: Equipment = {
                ...equipment,
                data: substituteData,
              } as Equipment; //needed to enter getEquipmentPower
              substitute.power = getEquipmentPower(substitute);
              substitute.isSubstitute = true;
              substitute.consumption = getEquipmentConsumption(
                equipment,
                substitute.power,
                eqPhases,
              );
              substitute.consumptionOfReplaced = equipment.consumption;
              substitutes.push(substitute);
              const reduction = equipment.consumption - substitute.consumption;
              if (reduction > maxReduction) maxReduction = reduction;
            }
          });
        }
      }
      return {
        ...equipment,
        substitutes,
        maxReduction,
      };
    });
    switch (equipmentType) {
      case 'light':
        equipmentComplete.light = equipmentsComplete as Light[];
        break;
      case 'video':
        equipmentComplete.video = equipmentsComplete as Video[];
        break;
      case 'prod':
        equipmentComplete.prod = equipmentsComplete as Prod[];
        break;
      case 'rigg':
        equipmentComplete.rigg = equipmentsComplete as Rigg[];
        break;
      case 'amp':
        equipmentComplete.amp = equipmentsComplete as Amp[];
        break;
    }
  });
  return equipmentComplete;
};

const getConsumptionPerPhase = (zone: Zone, factor: number) => {
  return zone.phases.map((phase: Phase) => {
    return {
      name: phase.name,
      value: Math.round(phase.consumption) / factor,
      fill: phase.color,
    };
  });
};

const getConsumptionPerEquipmentType = (zone: Zone, factor: number) => {
  const equipments: Equipment[] = getFlattenEquipments(zone.equipment);
  const equipmentTypes = ['rigg', 'light', 'video', 'amp', 'prod'];
  return equipmentTypes.map((equipmentType: string) => {
    return {
      name: equipmentNames[equipmentType],
      value: equipments
        .filter((eq: Equipment) => eq.type === equipmentType)
        .reduce(
          (total: number, eq: Equipment) => total + eq.consumption / factor,
          0,
        ),
      fill: equipmentTypeColors[equipmentType as keyof EquipmentList],
    };
  });
};

export const getFlattenEquipments = (equipmentList: EquipmentList) => {
  return Object.values(equipmentList).reduce(
    (flattenEquipments, equipments) => {
      return [
        ...(flattenEquipments as Equipment[]),
        ...(equipments as Equipment[]),
      ];
    },
    [],
  ) as Equipment[];
};

export default getZoneComplete;
