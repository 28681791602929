import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { phaseValues } from './config';

import { Delete, Edit } from '@mui/icons-material';

import useZone from './context/useZone';
import PhaseForm from './PhaseForm';

import { Phase, Zone } from '@showatt/core';

import LoadingButton from '../../ui/LoadingButton';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';

interface Props {
  zone: Zone;
}

const PhasesTable = ({ zone }: Props) => {
  const { phases } = zone;
  const [selectedPhase, setSelectedPhase] = useState<Phase | undefined>(
    undefined,
  );
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const phaseTypes = phaseValues[zone.type];

  const handleRowClick = (params: any) => {
    const phase = phases.find((p: Phase) => p.id === params.row.index);
    setSelectedPhase(phase);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setSelectedPhase(undefined);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setSelectedPhase(undefined);
  };

  const handleEdit = async (row: any) => {
    const phase = phases.find((p: Phase) => p.id === row.index);
    await setSelectedPhase(phase);
    setOpenEdit(true);
  };

  const handleDelete = async (e: React.MouseEvent<HTMLElement>, row: any) => {
    const phase = phases.find((p: Phase) => p.id === row.index);
    e.stopPropagation();
    await setSelectedPhase(phase);
    setOpenDelete(true);
  };

  let columns: GridColDef[] = [
    { field: 'phase', headerName: 'Type', width: 220 },
    {
      field: 'name',
      headerName: 'Nom de la phase',
      width: 250,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: 20,
              height: 20,
              borderRadius: '50%',
              backgroundColor: params.row.color,
              marginRight: 8,
            }}
          ></div>
          {params.value}
        </div>
      ),
    },

    {
      field: 'actions',
      headerName: '',
      width: 120,
      renderCell: (params) => (
        <>
          <IconButton
            color="primary"
            aria-label="Edit"
            onClick={() => handleEdit(params.row)}
          >
            <Edit />
          </IconButton>
          <IconButton
            color="primary"
            aria-label="Delete"
            onClick={(e) => handleDelete(e, params.row)}
          >
            <Delete />
          </IconButton>
        </>
      ),
    },
  ];

  const simulationType = zone.simulationType;

  if (simulationType === 'Événement ponctuel') {
    columns.splice(
      2,
      0,
      { field: 'dateStart', headerName: 'Date début', width: 200 },
      { field: 'dateEnd', headerName: 'Date Fin', width: 200 },
      {
        field: 'duration',
        headerName: 'Durée',
        width: 100,
        renderCell: (params) => <>{params.value} h</>,
      },
    );
  } else if (simulationType === 'Consommation annuelle') {
    columns.splice(
      2,
      0,
      ...[
        {
          field: 'nbDays',
          headerName: 'Nombre de jours',
          width: 150,
        },
        {
          field: 'durationPerDay',
          headerName: 'Durée / jour (h)',
          width: 150,
        },
      ],
    );
  }

  const rows = phases.map((phase: Phase, i: number) => ({
    id: i.toString(),
    index: phase.id,
    phase: phase.type,
    color: phase.color,
    duration: phase.duration,
    nbDays: phase.nbDays,
    durationPerDay: phase.durationPerDay,
    name: phase.name,
    dateStart: phase.dateStart
      ? `${new Date(phase.dateStart).toLocaleDateString()} à ${phase.hourStart}`
      : '',
    dateEnd: phase.dateEnd
      ? `${new Date(phase.dateEnd).toLocaleDateString()} à ${phase.hourEnd}`
      : '',
  }));

  return (
    <>
      {openEdit && (
        <PhaseForm
          open={openEdit}
          onClose={handleCloseEdit}
          phase={selectedPhase}
          phaseTypes={phaseTypes}
          simulationType={simulationType}
        />
      )}
      {openDelete && (
        <ModalDeletePhase
          open={openDelete}
          onClose={handleCloseDelete}
          phase={selectedPhase}
        />
      )}
      <DataGrid
        columns={columns}
        rows={rows}
        onCellClick={handleRowClick}
        rowHeight={36}
        sx={{
          '.MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer',
          },
        }}
      />
    </>
  );
};

interface ModalProps {
  open: boolean;
  onClose: () => void;
  phase?: Phase;
}

const ModalDeletePhase = ({ phase, open, onClose }: ModalProps) => {
  const { deletePhase } = useZone();
  if (!phase) return null;
  const handleDelete = async () => {
    await deletePhase(phase);
    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Suppression Phase</DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
      >
        <p className="hxr">
          Êtes vous sûr de vouloir supprimer la phase <b>"{phase.name}"</b> ?
        </p>
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={onClose} color="secondary">
          Annuler
        </LoadingButton>
        <LoadingButton color="primary" onClick={handleDelete}>
          Valider
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default PhasesTable;
