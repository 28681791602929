import {
  Amp,
  Bar1DBar,
  Bar1DData,
  Equipment,
  EquipmentList,
  Light,
  Phase,
  Prod,
  Rigg,
  Speaker,
  Video,
  Zone,
} from '@showatt/core';

import {
  equipmentNames,
  equipmentTypeColors,
  phaseTypeWeight,
  Weights,
} from '../zone/config';

export const getPhaseDuration = (phase: Phase, simulationType: string) => {
  if (!simulationType) return 0;
  if (simulationType === 'Consommation annuelle') {
    return phase.nbDays * phase.durationPerDay;
  } else if (simulationType === 'Événement ponctuel') {
    const startDateTime = new Date(
      `${
        new Date(phase.dateStart).toISOString().split('T')[0]
      }T${phase.hourStart.replace('h', ':')}:00.000Z`,
    );
    const endDateTime = new Date(
      `${
        new Date(phase.dateEnd).toISOString().split('T')[0]
      }T${phase.hourEnd.replace('h', ':')}:00.000Z`,
    );
    const durationInMilliseconds =
      endDateTime.getTime() - startDateTime.getTime();
    const durationInHours = durationInMilliseconds / (1000 * 60 * 60);
    return durationInHours;
  }
  return 0;
};

export const getPhasesDuration = (
  equipmentPhases: Phase[],
  simulationType: string,
) => {
  let totalDuration = 0;
  for (const phase of equipmentPhases) {
    const durationInHours = getPhaseDuration(phase, simulationType);
    totalDuration += durationInHours;
  }
  return totalDuration;
};

export const getEquipmentPower = (equipment: Equipment) => {
  switch (equipment.type) {
    case 'light':
      return getLightPower(equipment);
    case 'rigg':
      return getRiggPower(equipment);
    case 'video':
      return getVideoPower(equipment);
    case 'prod':
      return getProdPower(equipment);
    case 'amp':
      return getAmpPower(equipment);
    default:
      return 0;
  }
};

const getLightPower = (light: Light) => {
  return light.data.power * light.quantity;
};

const getRiggPower = (rigg: Rigg) => {
  return rigg.data.power * rigg.quantity;
};

const getProdPower = (prod: Prod) => {
  return prod.data.power * prod.quantity;
};

const getAmpPower = (amp: Amp) => {
  let outputPowers = amp.speakers.map((speakers: Speaker[]) => {
    return getOutputPower(amp, speakers);
  });
  let ampPower = outputPowers.reduce(
    (total, outputPower) => total + outputPower,
    0,
  );
  return ampPower * amp.quantity;
};

const getOutputPower = (amp: Amp, speakers: Speaker[]) => {
  if (speakers.length === 0) return amp.data.idlePower;
  const gg0 = 2.4;
  const Ve = 2;
  const impedence =
    1 /
    speakers.reduce(
      (totalImpedence, speaker) =>
        totalImpedence + (1 * speaker.quantity) / speaker.data.impedence,
      0,
    );
  const alpha = 1; /////// TO UPDATE
  return amp.data.idlePower + alpha * ((Math.exp(gg0) * Ve) / impedence);
};

const getVideoPower = (video: Video) => {
  const power =
    video.consumptionType === 'Moyenne'
      ? video.data.consom_moy
      : video.data.consom_max;
  return power * video.quantity;
};

export const getZoneData = (zone: Zone) => {
  let zoneConsumption = 0;
  const phases = zone.phases.map((phase): Phase => {
    const power = getPhasePower(phase.id, phase.type, zone.equipment);
    const consumption = power * phase.duration;
    zoneConsumption += consumption;
    return {
      ...phase,
      power,
      consumption,
    };
  });
  const equipmentList = getUpdatedEquipmentList(zone.equipment, phases);
  const updatedZone = {
    ...zone,
    phases,
    consumption: zoneConsumption,
    equipment: equipmentList,
  };
  return updatedZone;
};

const getUpdatedEquipmentList = (
  equipmentList: EquipmentList,
  phases: Phase[],
) => {
  const updatedEquipment: EquipmentList = {
    light: getUpdatedLights(equipmentList.light, phases),
    rigg: getUpdatedRiggs(equipmentList.rigg, phases),
    video: getUpdatedVideos(equipmentList.video, phases),
    amp: getUpdatedAmps(equipmentList.amp, phases),
    prod: getUpdatedProds(equipmentList.prod, phases),
  };
  return updatedEquipment;
};

const getUpdatedLights = (equipments: Light[], phases: Phase[]) => {
  return equipments.map((equipment: Light) => {
    const duration = getEquipmentDuration(equipment.phases, phases);
    const equipmentPower = getEquipmentPower(equipment);
    const consumption = getEquipmentConsumption(
      equipment,
      equipmentPower,
      phases,
    );
    return {
      ...equipment,
      duration,
      consumption,
    };
  });
};

const getUpdatedProds = (equipments: Prod[], phases: Phase[]) => {
  return equipments.map((equipment: Prod) => {
    const duration = getEquipmentDuration(equipment.phases, phases);
    const equipmentPower = getEquipmentPower(equipment);
    const consumption = getEquipmentConsumption(
      equipment,
      equipmentPower,
      phases,
    );
    return {
      ...equipment,
      duration,
      consumption,
    };
  });
};

const getUpdatedRiggs = (equipments: Rigg[], phases: Phase[]) => {
  return equipments.map((equipment: Rigg) => {
    const duration = getEquipmentDuration(equipment.phases, phases);
    const equipmentPower = getEquipmentPower(equipment);
    const consumption = getEquipmentConsumption(
      equipment,
      equipmentPower,
      phases,
    );
    return {
      ...equipment,
      duration,
      consumption,
    };
  });
};

const getUpdatedVideos = (equipments: Video[], phases: Phase[]) => {
  return equipments.map((equipment: Video) => {
    const duration = getEquipmentDuration(equipment.phases, phases);
    const equipmentPower = getEquipmentPower(equipment);
    const consumption = getEquipmentConsumption(
      equipment,
      equipmentPower,
      phases,
    );
    return {
      ...equipment,
      duration,
      consumption,
    };
  });
};

const getUpdatedAmps = (equipments: Amp[], phases: Phase[]) => {
  return equipments.map((equipment: Amp) => {
    const duration = getEquipmentDuration(equipment.phases, phases);
    const equipmentPower = getEquipmentPower(equipment);
    const consumption = getEquipmentConsumption(
      equipment,
      equipmentPower,
      phases,
    );
    return {
      ...equipment,
      duration,
      consumption,
    };
  });
};

const getEquipmentDuration = (equipmentPhases: string[], phases: Phase[]) => {
  return phases.reduce(
    (total, phase) =>
      equipmentPhases.includes(phase.id) ? total + phase.duration : total,
    0,
  );
};

export const getEquipmentConsumption = (
  equipment: Equipment,
  equipmentPower: number,
  phases: Phase[],
) => {
  return phases
    .filter((phase) => equipment.phases.includes(phase.id))
    .reduce((total, phase) => {
      const weights: Weights =
        phaseTypeWeight[equipment.type as keyof EquipmentList];
      const weight = weights[phase.type as keyof Weights];
      const consumption = equipmentPower * phase.duration * weight;
      return total + consumption;
    }, 0);
};

const getPhasePower = (
  phaseId: string,
  phaseType: string,
  equipmentList: EquipmentList,
) => {
  return Object.values(equipmentList).reduce(
    (totalEquipments, equipments: Equipment[]) => {
      return (
        totalEquipments +
        equipments.reduce((totalEquipment, equipment: Equipment) => {
          const weights: Weights =
            phaseTypeWeight[equipment.type as keyof EquipmentList];
          const power = equipment.power * weights[phaseType as keyof Weights];
          return equipment.phases.includes(phaseId)
            ? totalEquipment + power
            : totalEquipment;
        }, 0)
      );
    },
    0,
  );
};

export const getPeakPower = (zone: Zone) => {
  return zone.phases.reduce(
    (total: number, phase: Phase) => phase.power + total,
    0,
  );
};

export const getPowerPerEquipment = (zone: Zone) => {
  let bars: Bar1DBar[] = [];
  let data: Bar1DData[] = [];
  Object.keys(zone.equipment).forEach((equipmentType) => {
    const equipments: Equipment[] =
      zone.equipment[equipmentType as keyof EquipmentList];
    const totalPower = equipments.reduce(
      (total: number, eq: Equipment) => total + eq.power,
      0,
    );
    if (totalPower > 0) {
      bars.push({
        name: equipmentNames[equipmentType],
        color: equipmentTypeColors[equipmentType],
      });
      data.push({
        name: equipmentNames[equipmentType],
        [equipmentNames[equipmentType]]: Math.round(totalPower),
      });
    }
  });
  return {
    data,
    bars,
  };
};

export const getPowerPerPhase = (zone: Zone) => {
  let bars: Bar1DBar[] = [];
  let data: Bar1DData[] = [];
  const phases = updatePhaseNames(zone.phases); // update phase names if identical
  phases.forEach((phase) => {
    bars.push({
      name: phase.name,
      color: phase.color,
    });
    data.push({
      name: phase.name,
      [phase.name]: Math.round(phase.power),
    });
  });
  return {
    data,
    bars,
  };
};

const updatePhaseNames = (phases: Phase[]) => {
  const nameCountMap: { [name: string]: number } = {};
  let updatedPhases: Phase[] = [];
  phases.forEach((phase) => {
    let { name } = phase;
    if (phases.filter((p) => p.name === phase.name).length > 1) {
      const count = nameCountMap[name] || 1;
      nameCountMap[name] = count + 1;
      name = `${name}_${count}`;
    }
    updatedPhases.push({ ...phase, name });
  });
  return updatedPhases;
};

export const getMaxReduction = (equipments: Equipment[]) => {
  return equipments.reduce((total, eq) => {
    return eq.maxReduction ? eq.maxReduction + total : total;
  }, 0);
};
