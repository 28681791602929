import styled from '@emotion/styled';
import { ReactNode, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import useUser from '../authentication/context/useUser';
import { getProjectTitle, useProject } from '../project/context/useProject';

import { ReactComponent as Export } from '../ui/icons/export.svg';
import { ReactComponent as Home } from '../ui/icons/home.svg';
import { ReactComponent as Next } from '../ui/icons/next.svg';

import ShareProject from '../project/ShareProject';

import Tooltip from '@mui/material/Tooltip';

import { Project, ProjectUser } from '@showatt/core';
import UserMenu from './UserMenu';

export const HeaderContainer = styled.header`
  height: 64px;
  width: 100%;
  position: sticky;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px;
  top: 0;
  background-color: white;
  z-index: 80;
`;

export const LogoWrapper = styled.img`
  max-width: 150px;
  max-height: 60%;
  cursor: pointer;
`;

export const HeaderProject = () => {
  const { projectId } = useParams();
  const { project } = useProject(projectId);
  const { user } = useUser();
  const isShareProject = project?.users.some(
    (u: ProjectUser) => u.id === user?._id && u.role === 'owner',
  );
  return (
    <HeaderContainer>
      <HeaderSection>
        {project && <ProjectBreadcrumb project={project!} />}
      </HeaderSection>
      <HeaderSection marginBetween="16px">
        {isShareProject && project && <ShareButton project={project} />}
        <UserMenu />
      </HeaderSection>
    </HeaderContainer>
  );
};

export const HeaderProjects = () => {
  return (
    <HeaderContainer>
      <HeaderSection>
        <p className="h3b">SHOWATT</p>
        {/* <LogoWrapper
          id="logoWrapper"
          src="/logo.png"
          alt="logo"
          onClick={() => navigate('/')}
        /> */}
      </HeaderSection>
      <HeaderSection>
        <UserMenu />
      </HeaderSection>
    </HeaderContainer>
  );
};

const ProjectBreadcrumbWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ProjectBreadcrumb = ({ project }: { project: Project }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const projectTitle = getProjectTitle(project);
  const isProjectResults =
    location.pathname.includes('/results') &&
    !location.pathname.includes('/zone');
  const isActions = location.pathname.includes('/actions');
  const isZone =
    location.pathname.includes('/zone') &&
    !location.pathname.includes('/results');
  const isZoneResults =
    location.pathname.includes('/results') &&
    location.pathname.includes('/zone');
  const isProject =
    !isProjectResults && !isActions && !isZone && !isZoneResults;
  const zoneLink = isZoneResults
    ? location.pathname.replace('results/', '')
    : '';
  return (
    <ProjectBreadcrumbWrapper>
      <IconContainer onClick={() => navigate('/')}>
        <Home />
      </IconContainer>
      <IconContainer>
        <Next />
      </IconContainer>
      {isProject ? (
        <p className="h6r">{projectTitle}</p>
      ) : (
        <Link to={`/project/${project._id}`} className="h6r underline">
          {projectTitle}
        </Link>
      )}
      {isProjectResults && (
        <>
          <IconContainer>
            <Next />
          </IconContainer>
          <p className="h6b">Rapport Complet Projet</p>
        </>
      )}
      {isActions && (
        <>
          <IconContainer>
            <Next />
          </IconContainer>
          <p className="h6b">Actions</p>
        </>
      )}
      {isZone && (
        <>
          <IconContainer>
            <Next />
          </IconContainer>
          <p className="h6b">Configuration de zone</p>
        </>
      )}
      {isZoneResults && (
        <>
          <IconContainer>
            <Next />
          </IconContainer>
          <Link to={zoneLink} className="h6r underline">
            Configuration de zone
          </Link>
          <IconContainer>
            <Next />
          </IconContainer>
          <p className="h6b">Rapport Complet Zone</p>
        </>
      )}
    </ProjectBreadcrumbWrapper>
  );
};

type HeaderSectionProps = {
  centered?: boolean;
  marginBetween?: string;
  children: ReactNode;
};

const HeaderSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  position: ${(props: HeaderSectionProps) =>
    props.centered ? 'absolute' : ''};
  left: ${(props: HeaderSectionProps) => (props.centered ? '50%' : '')};
  transform: ${(props: HeaderSectionProps) =>
    props.centered ? 'translate(-50%, 0)' : ''};
  > *:not(:last-child) {
    margin-right: ${(props: HeaderSectionProps) =>
      props.marginBetween ? props.marginBetween : ''};
  }
`;

export const HeaderSection = ({
  marginBetween,
  centered,
  children,
}: HeaderSectionProps) => {
  return (
    <HeaderSectionWrapper marginBetween={marginBetween} centered={centered}>
      {children}
    </HeaderSectionWrapper>
  );
};

const HeaderButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const ShareButton = ({ project }: { project: Project }) => {
  const [openShare, setOpenShare] = useState<boolean>(false);
  const handleShareProject = () => {
    setOpenShare(true);
  };
  return (
    <>
      <ShareProject
        open={openShare}
        onClose={() => setOpenShare(false)}
        project={project}
      />
      <Tooltip title="Partager le projet">
        <HeaderButton onClick={handleShareProject}>
          <IconContainer>
            <Export />
          </IconContainer>
          <p className="h6r">Partager</p>
        </HeaderButton>
      </Tooltip>
    </>
  );
};
