import styled from '@emotion/styled';
import SubdirectoryArrowRightOutlinedIcon from '@mui/icons-material/SubdirectoryArrowRightOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import { Tooltip } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Equipment } from '@showatt/core';
import { GraphTitle } from '../../results/ResultGraph';

const Container = styled('div')`
  position: relative;
  width: 100%;
  background-color: white;
  position: relative;
  padding: 32px;
  .row-substitute {
    background-color: var(--color-primary-very-light);
  }
  .row-equipment-to-replace {
    background-color: var(--color-secondary-very-light);
  }
  .row-equipment-ok {
    background-color: var(--color-primary-light);
  }
  > div {
    margin-top: 16px;
  }
`;

const ShowRecommandations = ({ equipments }: { equipments: Equipment[] }) => {
  if (equipments.length === 0) return null;

  const rows = getRows(equipments);
  const maxImpact = Math.max(...rows.map((row: any) => getImpactConso(row)));
  const columns = getColumns(maxImpact);

  if (rows.length === 0) return null;

  const rowClassGetter = (params: any) => {
    return params.row.isSubstitute
      ? 'row-substitute'
      : params.row.substitutes?.length === 0
      ? 'row-equipment-ok'
      : 'row-equipment-to-replace';
  };

  return (
    <Container>
      <GraphTitle text={`5 recommandations avec le plus d'impact`} />
      <DataGrid
        density="compact"
        columns={columns}
        rows={rows}
        getRowClassName={rowClassGetter}
        hideFooter={true}
      />
    </Container>
  );
};

const getRows = (equipments: Equipment[]) => {
  let rows: Equipment[] = [];
  let index = 0;
  equipments.forEach((equipment) => {
    rows.push({
      ...equipment,
      id: index.toString(),
    });
    index++;
    equipment.substitutes?.forEach((substitute: Equipment) => {
      rows.push({
        ...substitute,
        id: index.toString(),
      });
      index++;
    });
  });
  return rows;
};

const getColumns = (maxImpact: number): GridColDef[] => {
  const consoColumn = {
    field: 'conso',
    headerName: 'Conso',
    width: 150,
    valueGetter: getConso,
  };
  const impactColumn = {
    field: 'impact_conso',
    headerName: 'Impact Conso (Wh)',
    width: 150,
    valueGetter: getImpact,
    renderCell: (params: any) => {
      const row = params.row;
      if (!row) return null;
      const impact = getImpactConso(row);
      const diameter = (impact / maxImpact) * 24; // Calculate the diameter as a percentage of the maxImpact value
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: `${diameter}px`,
              height: `${diameter}px`,
              backgroundColor: 'var(--color-primary)',
              borderRadius: '50%',
              marginRight: '8px',
            }}
          />
          {params.value}
        </div>
      );
    },
  };

  const impactRelColumn = {
    field: 'impact_rel_conso',
    headerName: 'Impact Conso (%)',
    width: 150,
    valueGetter: getImpactRel,
  };

  let columns: GridColDef[] = [
    { field: 'zone', headerName: 'Zone', width: 150 },
    {
      field: 'ref',
      headerName: 'Reference',
      width: 150,
      renderCell: (params: any) => {
        const row = params.row;
        if (!row) return null;

        return (
          <Tooltip title={getRowTooltip(row)} arrow>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {row.isSubstitute && (
                <SubdirectoryArrowRightOutlinedIcon
                  style={{ marginRight: '8px', width: '18px', height: '18px' }}
                />
              )}
              {!row.isSubstitute && row.substitutes.length === 0 && (
                <TaskAltOutlinedIcon
                  style={{ marginRight: '8px', width: '18px', height: '18px' }}
                />
              )}
              {row.data.ref}
            </div>
          </Tooltip>
        );
      },
    },
    { field: 'marque', headerName: 'Marque', width: 150 },
    consoColumn,
    impactColumn,
    impactRelColumn,
  ];

  const getRowTooltip = (row: any) => {
    if (row.isSubstitute) {
      return 'Matériel de substitution suggéré';
    } else if (row.substitutes.length === 0) {
      return 'Matériel configuré dans la zone, optimisé en terme énergétique';
    } else {
      return `Matériel configuré dans la zone, pour lequel ${row.substitutes.length} matériel(s) sont moins consommateurs`;
    }
  };

  return columns;
};

const getImpactConso = (row: any) => {
  return row.isSubstitute
    ? Math.round(row.consumptionOfReplaced - row.consumption)
    : 0;
};

const getConso = (params: any) => {
  const row = params.row;
  if (!row) return '';
  const { consumption } = row;
  return `${Math.round(consumption)} Wh`;
};

const getImpact = (params: any) => {
  const row = params.row;
  if (!row) return '';
  const { isSubstitute, consumption, consumptionOfReplaced } = row;
  if (isSubstitute)
    return `${Math.round(consumption - consumptionOfReplaced)} Wh`;
  return '0 Wh';
};

const getImpactRel = (params: any) => {
  const row = params.row;
  if (!row) return '';
  const { isSubstitute, consumption, consumptionOfReplaced } = row;

  if (isSubstitute)
    return `${Math.round(
      ((consumption - consumptionOfReplaced) / consumptionOfReplaced) * 100,
    )} %`;
  return '0 %';
};

export default ShowRecommandations;
