import styled from '@emotion/styled';

import Add from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { phaseValues } from './config';
import useZone from './context/useZone';
import PhaseForm from './PhaseForm';
import PhasesTable from './PhasesTable';

import { useParams } from 'react-router-dom';
import {
  getProjectParameter,
  useProject,
} from '../../project/context/useProject';

const Container = styled('div')`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Header = styled('div')`
  width: 100%;
  > div {
    display: flex;
    gap: 16px;
    margin-top: 8px;
  }
`;

const Main = styled('div')`
  width: 100%;
  > div {
    display: flex;
    gap: 16px;
    margin-top: 8px;
  }
`;

const ZonePhases = () => {
  const [phaseTypes, setPhaseTypes] = useState<string[]>([]);
  const [phaseTypeSelected, setPhaseTypeSelected] = useState<string>('');
  const { projectId } = useParams();
  const { project } = useProject(projectId);
  const { zone } = useZone();
  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    if (zone && zone.type && zone.type in phaseValues) {
      setPhaseTypes(phaseValues[zone.type as string]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zone?.type]);
  if (!zone || !project) return null;
  const handleOpen = async (phaseType: string) => {
    await setPhaseTypeSelected(phaseType);
    setOpen(true);
  };
  const handleClose = () => {
    setPhaseTypeSelected('');
    setOpen(false);
  };
  const projectDateStart = getProjectParameter(
    project,
    'Date_start',
  )?.value?.toString();
  const simulationType = zone.simulationType;
  return (
    <Container>
      {open && (
        <PhaseForm
          open={open}
          onClose={handleClose}
          phaseTypeSelected={phaseTypeSelected}
          phaseTypes={phaseTypes}
          projectDateStart={projectDateStart}
          simulationType={simulationType}
        />
      )}
      <Header>
        <p className="h5b">Ajouter une phase</p>
        <div>
          {phaseTypes.map((phaseType) => (
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={() => handleOpen(phaseType)}
            >
              {phaseType}
            </Button>
          ))}
        </div>
      </Header>
      <Main>
        <p className="h5b">Phases</p>
        <div>
          <PhasesTable zone={zone} />
        </div>
      </Main>
    </Container>
  );
};

export default ZonePhases;
