import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import { useNavigate } from 'react-router-dom';
import Pie1DComponent from '../../results/Pie1DComponent';
import Button from '../../ui/Button';
import Indicator from './Indicator';

import {
  Indicator as IndicatorType,
  Pie1D,
  Project,
  Result,
} from '@showatt/core';

import {
  Container,
  GraphContainer,
  IndicatorsContainer,
  ResultSection,
  Title,
} from './Partials';

const ShowResultsSimple = ({ project }: { project: Project }) => {
  const navigate = useNavigate();
  const { results } = project;
  return (
    <Container>
      <ResultSection>
        <Title>Empreinte du projet</Title>
        <IndicatorsContainer>
          <Indicator
            indicator={
              {
                ...results[0],
                color: 'var(--color-primary-light)',
              } as IndicatorType
            }
          />
          <Indicator
            indicator={
              {
                ...results[1],
                color: 'var(--color-primary-light)',
              } as IndicatorType
            }
          />
          {/* <Indicator
            indicator={zoneComplete.resultsSimple.indicatorReduction}
          /> */}
        </IndicatorsContainer>
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'var(--color-accent)',
            color: 'white',
            height: '40px',
          }}
          onClick={() => navigate(`./results`)}
          fullWidth
          startIcon={<TipsAndUpdatesOutlinedIcon />}
        >
          Rapport Complet Projet
        </Button>
      </ResultSection>
      <ResultSection style={{ flex: '1' }}>
        <Title>Empreinte détaillée</Title>
        <GraphContainer>
          <div>
            <Pie1DComponent
              result={
                results.find(
                  (r: Result) =>
                    r.scope === 'simple' &&
                    'code' in r &&
                    r.code === 'pie1DconsumptionPerZone',
                ) as Pie1D
              }
            />
          </div>
          <div>
            <Pie1DComponent
              result={
                results.find(
                  (r: Result) =>
                    r.scope === 'simple' &&
                    'code' in r &&
                    r.code === 'pie1DconsumptionPerEq',
                ) as Pie1D
              }
            />
          </div>
        </GraphContainer>
        <GraphContainer style={{ flex: '0', marginTop: '-12px' }}>
          <p style={{ fontSize: '12px' }}>Par zone</p>
          <p style={{ fontSize: '12px' }}>Par équipement</p>
        </GraphContainer>
      </ResultSection>
    </Container>
  );
};

export default ShowResultsSimple;
