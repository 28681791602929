import styled from '@emotion/styled';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
} from '@mui/material';
import { Phase } from '@showatt/core';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import ListSelect from '../../project/parameters/ListSelect';
import TextField from '../../project/parameters/TextField';
import LoadingButton from '../../ui/LoadingButton';
import useZone from './context/useZone';

import { hours } from './config';

interface PhaseFormProps {
  open: boolean;
  onClose: () => void;
  phaseTypes?: string[];
  phaseTypeSelected?: string;
  phase?: Phase;
  projectDateStart?: string;
  simulationType: string;
}

const FormRow = styled('div')`
  display: flex;
  gap: 16px;
  align-items: center;
  > * {
    flex: 1;
  }
`;

const validationSchema = yup.object({
  type: yup.string().required('Champ obligatoire'),
  name: yup.string().required('Champ obligatoire'),
  dateStart: yup.string().required('Champ obligatoire'),
  hourStart: yup.string().required('Champ obligatoire'),
  dateEnd: yup.string().required('Champ obligatoire'),
  hourEnd: yup.string().required('Champ obligatoire'),
  nbDays: yup.string().required('Champ obligatoire'),
  durationPerDay: yup.string().required('Champ obligatoire'),
});

const PhaseForm = ({
  open,
  onClose,
  phaseTypeSelected,
  phaseTypes,
  phase,
  projectDateStart,
  simulationType,
}: PhaseFormProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const { addPhase, editPhase } = useZone();
  const { enqueueSnackbar } = useSnackbar();

  const isEdit = !!phase;

  const title = isEdit ? 'Edition Phase' : 'Nouvelle Phase';

  const dateStart = projectDateStart
    ? formatDate(convertStringToDate(projectDateStart))
    : formatDate(new Date());

  const initialValues = isEdit
    ? {
        ...phase,
        dateStart: formatDate(new Date(phase.dateStart)),
        dateEnd: formatDate(new Date(phase.dateEnd)),
      }
    : {
        type: phaseTypeSelected!,
        name: phaseTypeSelected!,
        dateStart: dateStart,
        hourStart: '18h00',
        dateEnd: dateStart,
        hourEnd: '20h00',
        nbDays: 0,
        durationPerDay: 0,
        consumption: 0,
        power: 0,
      };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const dateStart = new Date(values.dateStart);
      const dateEnd = new Date(values.dateEnd);
      const hourStart = values.hourStart;
      const hourEnd = values.hourEnd;

      if (
        dateEnd < dateStart ||
        (dateEnd.getTime() === dateStart.getTime() && hourEnd <= hourStart)
      ) {
        enqueueSnackbar(
          'La fin de la phase doit être configurée après le début de la phase.',
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          },
        );
        setLoading(false);
        return;
      }
      isEdit
        ? editPhase({
            id: phase.id,
            ...values,
            dateStart,
            dateEnd,
            consumption: phase.consumption,
            duration: phase.duration,
            power: phase.power,
            color: phase.color,
          })
        : addPhase({ ...values, dateStart, dateEnd });
      setLoading(false);
      formik.resetForm();
      onClose();
    },
  });
  const [dateEndValue, setDateEndValue] = useState(formik.values.dateEnd);

  useEffect(() => {
    if (open && nameInputRef) {
      setTimeout(() => {
        nameInputRef.current?.focus();
      }, 1);
    }

    if (formik.values.dateStart > formik.values.dateEnd) {
      formik.setFieldValue('dateEnd', formik.values.dateStart);
      setDateEndValue(formik.values.dateStart);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameInputRef, formik.values.dateStart]);
  useEffect(() => {
    if (open && nameInputRef) {
      setTimeout(() => {
        nameInputRef.current?.focus();
      }, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameInputRef]);
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
        >
          <FormControl>
            <ListSelect
              name="type"
              value={formik.values.type}
              onChange={formik.handleChange}
              label="Type de phase"
              options={phaseTypes!}
            />
          </FormControl>
          <FormControl>
            <TextField
              id="name"
              name="name"
              label="Nom de la phase"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              disabled={loading}
              fullWidth
              inputProps={{ ref: nameInputRef }}
              autoFocus
            />
          </FormControl>
          {simulationType === 'Consommation annuelle' && (
            <FormControl>
              <TextField
                id="nbDays"
                name="nbDays"
                label="Nombre de jours"
                value={formik.values.nbDays}
                onChange={formik.handleChange}
                error={formik.touched.nbDays && Boolean(formik.errors.nbDays)}
                helperText={formik.touched.nbDays && formik.errors.nbDays}
                disabled={loading}
                fullWidth
              />
            </FormControl>
          )}
          {simulationType === 'Consommation annuelle' && (
            <FormControl>
              <TextField
                id="durationPerDay"
                name="durationPerDay"
                label="Durée / jour (h)"
                value={formik.values.durationPerDay}
                onChange={formik.handleChange}
                error={
                  formik.touched.durationPerDay &&
                  Boolean(formik.errors.durationPerDay)
                }
                helperText={
                  formik.touched.durationPerDay && formik.errors.durationPerDay
                }
                disabled={loading}
                fullWidth
              />
            </FormControl>
          )}
          {simulationType === 'Événement ponctuel' && (
            <>
              <FormRow>
                <FormControl>
                  <TextField
                    id="dateStart"
                    name="dateStart"
                    label="Date de début"
                    type="date"
                    value={formik.values.dateStart}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.dateStart &&
                      Boolean(formik.errors.dateStart)
                    }
                    helperText={
                      formik.touched.dateStart && formik.errors.dateStart
                    }
                    disabled={loading}
                    InputLabelProps={{ shrink: true }}
                  ></TextField>
                </FormControl>
                <FormControl>
                  <ListSelect
                    name="hourStart"
                    value={formik.values.hourStart}
                    onChange={formik.handleChange}
                    label="Heure"
                    options={hours}
                  />
                </FormControl>
              </FormRow>
              <FormRow>
                <FormControl>
                  <TextField
                    id="dateEnd"
                    name="dateEnd"
                    label="Date de fin"
                    type="date"
                    value={dateEndValue}
                    onChange={(event) => {
                      formik.handleChange(event);
                      setDateEndValue(event.target.value);
                    }}
                    error={
                      formik.touched.dateEnd && Boolean(formik.errors.dateEnd)
                    }
                    helperText={formik.touched.dateEnd && formik.errors.dateEnd}
                    disabled={loading}
                    InputLabelProps={{ shrink: true }}
                  ></TextField>
                </FormControl>
                <FormControl>
                  <ListSelect
                    name="hourEnd"
                    value={formik.values.hourEnd}
                    onChange={formik.handleChange}
                    label="Heure"
                    options={hours}
                  />
                </FormControl>
              </FormRow>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={onClose} loading={false} color="secondary">
            Annuler
          </LoadingButton>
          <LoadingButton
            type="submit"
            loading={loading}
            color="primary"
            disabled={loading}
          >
            Valider
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const convertStringToDate = (strDate: string): Date => {
  const serialTimestamp = parseFloat(strDate);
  const baseDate = new Date('1899-12-31T00:00:00Z');
  const date = new Date(
    baseDate.getTime() + (serialTimestamp - 1) * 24 * 60 * 60 * 1000,
    // each serial up to 60 corresponds to a valid calendar date.
    // serial 60 is 1900-02-29. This date does not exist on the calendar.
    // we choose to interpret serial 60 (as well as 61) both as 1900-03-01
    // so, if the serial is 61 or over, we have to subtract 1.
  );
  return date;
};

export default PhaseForm;
