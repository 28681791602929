import styled from '@emotion/styled';
import Add from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { Autocomplete } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { Amp, Speaker, SpeakerData } from '@showatt/core';
import { useState } from 'react';
import { useQuery } from 'react-query';
import LoadingButton from '../../ui/LoadingButton';
import getEquipments from './getEquipments';

const OutputContainer = styled('div')`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: var(--backgroundColor);
  width: 100%;
`;

const customStyles = `
  .data-grid-wo-footer .MuiDataGrid-footerContainer {
    display: none !important;
  }
`;

const Rows = styled('div')`
display: flex;
flex-direction: column;
gap: 8px;
width: 100%;
`;

const RowContainer = styled('div')`
display: flex;
align-items: center;
gap: 16px;
width: 100%;
padding: 6px 12px;
`;

//MuiDataGrid-footerContainer

interface Props {
  open: boolean;
  onClose: () => void;
  onValidate: (speakers: Speaker[][], amp: Amp) => void;
  amp: Amp;
}

interface RowProps {
  id: string;
  rowIndex: number;
  outputIndex: number;
  speakerIndex: number;
  marque: string;
  reference: string;
  quantity: number;
}

const ModalSpeakers = ({ open, onClose, onValidate, amp }: Props) => {

  const [currentSpeakers, setCurrentSpeakers] = useState<Speaker[][]>(
    amp.speakers,
  );
  const [selectedSpeakers, setSelectedSpeakers] = useState<{
    [key: number]: number;
  }>({});
  const { data: db } = useQuery('equipments', () => getEquipments());
  const [loading, setLoading] = useState<boolean>(false);

  if (!db) return <p>Loading...</p>;

  // Function to handle adding a speaker to the specified output section
  const handleAddSpeaker = (index: number, value: string | undefined) => {
    const speaker = speakerDb.find((speaker) => speaker.ref === value);
    if (speaker) {
      const updatedSpeakers = [...currentSpeakers];
      updatedSpeakers[index].push({
        equipmentType: 'speaker',
        quantity: 1,
        data: speaker,
      });
      setCurrentSpeakers(updatedSpeakers);

      const selected = { ...selectedSpeakers };
      selected[index] = updatedSpeakers[index].length - 1;
      setSelectedSpeakers(selected);
    }
  };

  const handleDeleteSpeaker = (outputIndex: number, speakerIndex: number) => {
    const updatedSpeakers = [...currentSpeakers];
    updatedSpeakers[outputIndex].splice(speakerIndex, 1);
    setCurrentSpeakers(updatedSpeakers);

    const selected = { ...selectedSpeakers };
    if (selected[outputIndex] === speakerIndex) {
      delete selected[outputIndex];
    }
    setSelectedSpeakers(selected);
  };

  // Function to handle editing the quantity of a speaker in the specified output section
  const handleEditQuantity = (
    outputIndex: number,
    speakerIndex: number,
    quantity: number,
  ) => {
    const updatedSpeakers = [...currentSpeakers];
    updatedSpeakers[outputIndex][speakerIndex].quantity = quantity;
    setCurrentSpeakers(updatedSpeakers);
  };

  const handleValidate = async () => {
    setLoading(true);
    await onValidate(currentSpeakers, amp);
    onClose();
    setLoading(false);
  };

  const Row = ({
    id,
    rowIndex,
    outputIndex,
    speakerIndex,
    marque,
    reference,
    quantity,
  }: RowProps) => {
    const className = rowIndex % 2 === 0 ? 'even-row' : 'odd-row';
    return (
      <RowContainer key={id} className={className}>
        <p>{marque}</p>
        <p>{reference}</p>
        <TextField
          type="number"
          size="small"
          variant="outlined"
          value={quantity}
          onChange={(e) =>
            handleEditQuantity(
              outputIndex,
              speakerIndex,
              parseInt(e.target.value, 10),
            )
          }
          inputProps={{
            min: 0,
            step: 1,
            // pattern: '\\d*',
          }}
          InputProps={{
            style: { backgroundColor: 'white' },
            inputProps: {
              style: { textAlign: 'center' },
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => handleDeleteSpeaker(outputIndex, speakerIndex)}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  <DeleteIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </RowContainer>
    );
  };

  const speakerDb: SpeakerData[] = db.speaker as SpeakerData[];

  return (
    <Dialog open={open} maxWidth="xl" fullWidth={true}>
      <DialogTitle>
        Configuration des enceintes pour <b>{amp.data.ref}</b>
      </DialogTitle>
      <DialogContent>
        <Alert severity="info" sx={{ marginBottom: '16px' }}>
          <AlertTitle>Info</AlertTitle>
          Cet utilitaire vous permet de configurer les enceintes associées à
          l'amplificateur {amp.data.ref}. <br />
          Sélectionnez au moins une enceinte pour que l'amplificateur soit pris
          en compte.
        </Alert>
        <Grid container spacing={4}>
          {amp.speakers.map((output, index) => (
            <Grid item sm={12} md={12} lg={6} key={index}>
              <OutputContainer>
                <p className="h4b">Canal {index + 1}</p>
                <Autocomplete
                  options={speakerDb.sort(
                    (a, b) => -b.marque.localeCompare(a.marque),
                  )}
                  getOptionLabel={(option) => option.ref}
                  groupBy={(option) => option.marque}
                  renderInput={(params) => (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '16px',
                      }}
                    >
                      <TextField
                        {...params}
                        label={`Ajouter une enceinte au canal ${index + 1}`}
                        variant="outlined"
                        sx={{ flex: 1, width: 'unset !important' }}
                      />
                      <Button
                        onClick={() =>
                          handleAddSpeaker(
                            index,
                            params.inputProps.value as string,
                          )
                        }
                        variant="contained"
                        onMouseDown={(e) => e.preventDefault()}
                        disabled={!params.inputProps.value}
                        color="primary"
                        startIcon={<Add />}
                        style={{
                          whiteSpace: 'nowrap',
                          padding: '4px 12px',
                          height: '56px',
                        }}
                      >
                        Ajouter enceinte
                      </Button>
                    </div>
                  )}
                />
                {output.length > 0 && (
                  <Rows>
                    {output.map((speaker, speakerIndex) => (
                      <Row
                        id={`${index}-${speakerIndex}`}
                        rowIndex={speakerIndex}
                        outputIndex={index}
                        speakerIndex={speakerIndex}
                        marque={speaker.data.marque}
                        reference={speaker.data.ref}
                        quantity={speaker.quantity}
                      />
                    ))}
                  </Rows>
                )}
              </OutputContainer>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={onClose} loading={false} color="secondary">
          Annuler
        </LoadingButton>
        <LoadingButton
          onClick={handleValidate}
          loading={loading}
          color="primary"
          disabled={loading}
        >
          Valider
        </LoadingButton>
      </DialogActions>
      <style>{customStyles}</style>
    </Dialog>
  );
};

export default ModalSpeakers;
