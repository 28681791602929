import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import useProjects from '../project/context/useProjects';
import { ReactComponent as AddIcon } from '../ui/icons/add.svg';

import CompletionBar from '../project/CompletionBar';

const TextIcon = styled.div`
  display: inline;
  white-space: nowrap;
  background-color: var(--yellow);
  padding: 4px 8px;
  border-radius: 4px;

  svg {
    position: relative;
    top: 4px;
    width: 20px;
    height: 20px;
  }
`;

const Indicator = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const useSteps = () => {
  const [active, setActive] = useLocalStorage(
    'seeds-onboarding-checklist-step',
    0,
  );

  const navigate = useNavigate();

  const { projects } = useProjects();

  const mostCompletedGame = projects
    ?.filter((project) => project.model.type === 'game')
    .sort((a, b) => b.completionRate - a.completionRate)[0];

  const mostCompletedSite = projects
    ?.filter((project) => project.model.type === 'site')
    .sort((a, b) => b.completionRate - a.completionRate)[0];

  const moveChecklistStep = (amount: number) => {
    setActive(active + amount);
  };

  const steps = [
    {
      title: 'Créez un local',
      content: (
        <div>
          Pour créer un local:
          <ol>
            <li>
              Rendez-vous sur{' '}
              <a
                href="/"
                onClick={(event) => {
                  event.preventDefault();
                  navigate('/');
                }}
              >
                la page d'accueil
              </a>
            </li>
            <li>
              Cliquez sur le bouton{' '}
              <TextIcon style={{ backgroundColor: 'var(--green)' }}>
                <AddIcon /> local
              </TextIcon>{' '}
              en <b>bas</b> de l'interface.
            </li>
            <li>Laissez vous guider</li>
          </ol>
        </div>
      ),
      success: projects?.some((project) => project.model.type === 'site'),
    },
    {
      title: 'Renseignez votre local à plus de 50%',
      content: (
        <div>
          {mostCompletedSite && (
            <>
              <Indicator>
                <p>
                  Complété à <b>{mostCompletedSite.completionRate}%</b>
                </p>
                <CompletionBar
                  completion={mostCompletedSite.completionRate}
                ></CompletionBar>
              </Indicator>
              <ol>
                <li>
                  Rendez-vous sur{' '}
                  <a
                    href="/"
                    onClick={(event) => {
                      event.preventDefault();
                      if (mostCompletedSite) {
                        navigate('/project/' + mostCompletedSite?._id);
                      }
                    }}
                  >
                    la page du projet
                  </a>
                </li>
                <li>
                  Continuez de compléter le formulaire jusqu'à atteindre 50% de
                  complétion
                </li>
              </ol>
            </>
          )}
          {!mostCompletedSite && (
            <>
              Commencez par{' '}
              <a
                href="/"
                onClick={(event) => {
                  event.preventDefault();
                  moveChecklistStep(-1);
                }}
              >
                créer un local
              </a>
            </>
          )}
        </div>
      ),
      locked: !projects?.some((project) => project.model.type === 'site'),
      success: projects?.some(
        (project) =>
          project.model.type === 'site' && project.completionRate > 50,
      ),
    },
    {
      title: 'Créez un jeu',
      content: (
        <div>
          Pour créer un jeu:
          <ol>
            <li>
              Rendez-vous sur{' '}
              <a
                href="/"
                onClick={(event) => {
                  event.preventDefault();
                  navigate('/');
                }}
              >
                la page d'accueil
              </a>
            </li>
            <li>
              Cliquez sur le bouton{' '}
              <TextIcon>
                <AddIcon /> jeu
              </TextIcon>{' '}
              en haut à gauche de l'interface.
            </li>
            <li>Laissez vous guider</li>
          </ol>
        </div>
      ),
      success: projects?.some((project) => project.model.type === 'game'),
    },
    {
      title: 'Renseignez un jeu à plus de 50%',
      content: (
        <div>
          {mostCompletedGame && (
            <>
              <Indicator>
                <p>
                  Complété à <b>{mostCompletedGame.completionRate}%</b>
                </p>
                <CompletionBar
                  completion={mostCompletedGame.completionRate}
                ></CompletionBar>
              </Indicator>
              <ol>
                <li>
                  Rendez-vous sur{' '}
                  <a
                    href="/"
                    onClick={(event) => {
                      event.preventDefault();
                      if (mostCompletedGame) {
                        navigate('/project/' + mostCompletedGame?._id);
                      }
                    }}
                  >
                    la page d'un jeu
                  </a>
                </li>
                <li>
                  Continuez de compléter le formulaire jusqu'à atteindre 50% de
                  complétion
                </li>
              </ol>
            </>
          )}
          {!mostCompletedGame && (
            <>
              Commencez par{' '}
              <a
                href="/"
                onClick={(event) => {
                  event.preventDefault();
                  moveChecklistStep(-1);
                }}
              >
                créer un jeu
              </a>
            </>
          )}
        </div>
      ),
      locked: !projects?.some((project) => project.model.type === 'game'),
      success: projects?.some(
        (project) =>
          project.model.type === 'game' && project.completionRate > 50,
      ),
    },
  ];

  return { active, setActive, steps };
};

export default useSteps;
