import styled from '@emotion/styled';
import { Subtitle, Title } from '@showatt/core';
import { categoryIcons } from '../project/Nav';
import { SectorHeaderWrapper } from '../project/Project';
import { zoneIcons } from '../show/zone/config';
import { IconContainer, scopeIcons } from './ResultsSimple';

const SectionTitleContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 16px;
  color: black;
  margin-bottom: 16px;
  .result_anchor {
    visibility: hidden;
    position: absolute;
    top: -48px;
  }
`;

const SectionTitle = ({ result }: { result: Title }) => {
  let elementIcon = undefined;
  if (result.icon && result.icon in zoneIcons) {
    const zoneIcon = zoneIcons[result.icon];
    elementIcon = categoryIcons[zoneIcon];
  } else {
    elementIcon = scopeIcons[result.icon!];
  }
  return (
    <SectionTitleContainer>
      <div id={`results_${result.code}`} className="result_anchor"></div>
      <IconContainer>{elementIcon}</IconContainer>
      <p className="h4b">{result.text}</p>
    </SectionTitleContainer>
  );
};

const SectionSubtitleContainer = styled.div`
  width: 100%;
  position: relative;
  padding: 16px;
  background-color: white;
  > div {
    margin-bottom: 0 !important;
  }
`;

export const SectionSubtitle = ({ result }: { result: Subtitle }) => {
  return (
    <SectionSubtitleContainer>
      <SectorHeaderWrapper
        backgroundColor={'var(--color-secondary-very-light)'}
        className={`level0`}
        style={{ zIndex: '1' }}
      >
        <p className="h4b">{result.text}</p>
      </SectorHeaderWrapper>
    </SectionSubtitleContainer>
  );
};

export default SectionTitle;
