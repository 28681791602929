import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import Alert, { AlertColor } from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Amp, Equipment, Zone } from '@showatt/core';
import { uniq } from 'lodash';
import { useState } from 'react';
import { equipmentNames } from './config';

const ZoneAlert = ({ zone }: { zone: Zone }) => {
  const [open, setOpen] = useState<boolean>(false);
  const alert = getZoneAlert(zone);
  const handleAlertClick = () => {
    setOpen(true);
  };
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{alert.title}</DialogTitle>
        <DialogContent>
          {alert.fullMessage.map((msg, index) => (
            <p key={index}>{msg}</p>
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(false)}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Alert
        severity={alert.severity}
        onClick={handleAlertClick}
        sx={{ cursor: 'pointer' }}
      >
        {alert.title.length > 0 && <AlertTitle>{alert.title}</AlertTitle>}
        {alert.message.length > 0 && alert.message.map((msg) => <p>{msg}</p>)}
      </Alert>
    </>
  );
};

const getZoneAlert = (zone: Zone) => {
  const equipmentsWOPhase: Equipment[] = getEqWOPhase(zone);
  const ampWOEncl: Amp[] = getAmpWOEncl(zone);
  const { severity, title, message, fullMessage } = getAlertInfos(equipmentsWOPhase, ampWOEncl);
  return {
    severity,
    title,
    message,
    fullMessage,
  };
};

const getEqWOPhase = (zone: Zone) => {
  let equipmentsWOPhase: Equipment[] = [];
  Object.values(zone.equipment).forEach((equipments) => {
    equipmentsWOPhase.push(
      ...equipments.filter((eq: Equipment) => eq.phases.length === 0),
    );
  });
  return equipmentsWOPhase;
}

const getAmpWOEncl = (zone: Zone) => {
  let ampWOEncl: Amp[] = [];
  ampWOEncl.push(
    ...zone.equipment.amp.filter(
      (amp) =>
        amp.speakers.reduce((total, speaker) => total + speaker.length, 0) ===
        0,
    ),
  );
  return ampWOEncl;
}

const getAlertInfos = (equipmentsWOPhase: Equipment[], ampWOEncl: Amp[]) => {

  //initialize alert settings
  let severity: AlertColor = 'success';
  let title: string = '';
  let message: string[] = [];
  let fullMessage: string[] = [];

  const nbEqWOPhase = equipmentsWOPhase.length;
  const nbAmpWOEncl = ampWOEncl.length;

  const eqWIssue = [
    ...equipmentsWOPhase.map((eq) => eq.id),
    ...ampWOEncl.map((eq) => eq.id),
  ];
  const nbEqWIssues = uniq(eqWIssue).length;

  if (nbEqWIssues > 0) {
    severity = 'warning';
    title =
      nbEqWIssues > 1
        ? `${nbEqWIssues} matériels mal configurés`
        : '1 matériel mal configuré';
  }
  else {
    message = ['Zone correctement configurée'];
  }

  if (nbEqWOPhase > 0) {
    message.push(
      nbEqWOPhase > 1
        ? `${nbEqWOPhase} matériels ne sont associés à aucune phase`
        : `1 matériel n'est associé à aucune phase`,
    );
    equipmentsWOPhase.forEach((eq) => {
      fullMessage.push(
        `Le matériel ${equipmentNames[eq.type]} ${eq.data.marque} ${
          eq.data.ref
        } n'est associé à aucune phase`,
      );
    });
  }
  
  if (nbAmpWOEncl > 0) {
    message.push(
      nbAmpWOEncl > 1
        ? `${nbAmpWOEncl} amplis ne sont associés à aucune enceinte`
        : `1 ampli n'est associé à aucune enceinte`,
    );
    ampWOEncl.forEach((amp) => {
      fullMessage.push(
        `L'ampli ${equipmentNames[amp.type]} ${amp.data.marque} ${
          amp.data.ref
        } n'est associé à aucune enceinte`,
      );
    });
  }

  return { severity, title, message, fullMessage }
}

export default ZoneAlert;
