import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import { Project, Zone } from '@showatt/core';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Bar1DComponent from '../../results/Bar1DComponent';
import Pie1DComponent from '../../results/Pie1DComponent';
import Button from '../../ui/Button';
import useZone from '../zone/context/useZone';
import Indicator from './Indicator';

import getEquipments from '../zone/getEquipments';
import getZoneComplete from './getZoneComplete';

import {
  Container,
  GraphContainer,
  IndicatorsContainer,
  ResultSection,
  Title,
} from './Partials';

const ZoneResults = ({ project }: { project: Project }) => {
  const navigate = useNavigate();
  const { zone, updateResults } = useZone();
  const [zoneComplete, setZoneComplete] = useState<Zone | undefined>(undefined);
  const { data: db } = useQuery('equipments', () => getEquipments());

  useEffect(() => {
    if (zone && db) {
      const zoneCompleteTemp = getZoneComplete(zone, db);
      setZoneComplete(zoneCompleteTemp);
      if (!isEqual(zoneCompleteTemp.resultsComplete, zone.resultsComplete))
        updateResults(zoneCompleteTemp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zone, db]);

  if (!zone || !zoneComplete || !zoneComplete.resultsSimple)
    return <p>Loading...</p>;

  if (zoneComplete.phases.length === 0)
    return (
      <Container>
        <p className="h4b">Consommation</p>
        <p>0 Wh</p>
      </Container>
    );

  return (
    <Container>
      <ResultSection>
        <Title>Empreinte de la zone</Title>
        <IndicatorsContainer>
          <Indicator
            indicator={zoneComplete.resultsSimple.indicatorConsumption}
          />
          <Indicator indicator={zoneComplete.resultsSimple.indicatorEmission} />
          <Indicator
            indicator={zoneComplete.resultsSimple.indicatorReduction}
          />
        </IndicatorsContainer>
        {/* <RecommendationFrame
          reduction={reduction}
        /> */}
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'var(--color-accent)',
            color: 'white',
            height: '40px',
          }}
          onClick={() =>
            navigate(`/project/${project._id}/zone/results/${zone.id}`)
          }
          fullWidth
          startIcon={<TipsAndUpdatesOutlinedIcon />}
        >
          Rapport Complet Zone
        </Button>
      </ResultSection>
      <ResultSection style={{ flex: '1' }}>
        <Title>Empreinte détaillée</Title>
        <GraphContainer>
          <div>
            <Pie1DComponent
              result={zoneComplete.resultsSimple.pieConsumptionByPhase}
            />
          </div>
          <div>
            <Pie1DComponent
              result={zoneComplete.resultsSimple.pieConsumptionByEquipmentType}
            />
          </div>
        </GraphContainer>
        <GraphContainer style={{ flex: '0', marginTop: '-12px' }}>
          <p style={{ fontSize: '12px' }}>Par phase</p>
          <p style={{ fontSize: '12px' }}>Par équipement</p>
        </GraphContainer>
      </ResultSection>
      <ResultSection style={{ flex: '1' }}>
        <Title>Puissance crête</Title>
        <GraphContainer>
          <div style={{ flex: '2' }}>
            <Bar1DComponent
              result={zoneComplete.resultsSimple.barPowerByEquipmentType}
            />
          </div>
          <Indicator
            indicator={zoneComplete.resultsSimple.indicatorPeakPower}
          />
        </GraphContainer>
      </ResultSection>
    </Container>
  );
};

export default ZoneResults;
