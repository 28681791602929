import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  Amp,
  Equipment,
  EquipmentList,
  Phase,
  Speaker,
  Video,
} from '@showatt/core';
import useZone from './context/useZone';
import { EquipmentEnum } from './Equipment';

import { useEffect, useRef, useState } from 'react';
import ModalSpeakers from './ModalSpeakers';

interface EquipmentTableProps {
  equipmentType: keyof typeof EquipmentEnum;
}

type Config = {
  [key in EquipmentEnum]: {
    extraColumns: string[];
  };
};

const config: Config = {
  amp: {
    extraColumns: ['speaker'],
  },
  video: {
    extraColumns: ['consumption'],
  },
  rigg: {
    extraColumns: [],
  },
  light: {
    extraColumns: [],
  },
  prod: {
    extraColumns: [],
  },
};

const EquipmentTable = ({ equipmentType }: EquipmentTableProps) => {
  const { zone, editEquipment, deleteEquipment } = useZone();
  const [openSpeakers, setOpenSpeakers] = useState<boolean>(false);
  const [selectedEquipment, setSelectedEquipment] = useState<Amp | undefined>(
    undefined,
  );

  const previousNbEquipments = useRef(zone?.equipment[equipmentType].length);

  useEffect(() => {
    if (equipmentType === 'amp') {
      const nbAmp = zone?.equipment.amp.length;
      if (
        typeof nbAmp !== 'undefined' &&
        typeof previousNbEquipments.current !== 'undefined' &&
        nbAmp > 0 &&
        nbAmp > previousNbEquipments.current
      ) {
        const amp = zone?.equipment.amp[nbAmp - 1];
        handleOpenSpeakers(amp!);
      }
      previousNbEquipments.current = zone?.equipment.amp.length;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zone?.equipment.amp]);

  if (!zone) return null;

  const { equipment, phases } = zone;
  const equipments: Equipment[] = (equipment as EquipmentList)[equipmentType];

  // HANDLE NO PHASE

  const handleQuantityChange = (equipment: Equipment, quantity: number) => {
    editEquipment({ ...equipment, quantity }, equipmentType);
  };

  const handleChecked = (
    equipment: Equipment,
    phaseId: string,
    isChecked: boolean,
  ) => {
    let phases = equipment.phases;
    let newPhases: string[] = [...phases];
    if (isChecked) {
      const index = phases.findIndex((p: string) => p === phaseId);
      if (index > -1) newPhases.splice(index, 1);
    } else {
      newPhases.push(phaseId);
    }
    editEquipment({ ...equipment, phases: newPhases }, equipmentType);
  };

  const handleDelete = (equipment: Equipment) => {
    deleteEquipment(equipment.id, equipmentType);
  };

  const columns: GridColDef[] = [
    { field: 'marque', headerName: 'Marque', width: 120 },
    { field: 'ref', headerName: 'Référence', width: 150 },
    {
      field: 'quantity',
      headerName: 'Quantité',
      width: 200,
      renderCell: (params) => {
        const equipment = params.row.data;
        const quantity = params.row.quantity;
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              type="number"
              size="small"
              variant="outlined"
              value={quantity}
              inputProps={{
                min: 0,
                step: 1,
                pattern: '\\d*',
              }}
              onChange={(e) =>
                handleQuantityChange(equipment, parseInt(e.target.value, 10))
              }
              onKeyPress={(e) => {
                if (e.key === 'e' || e.key === '.' || e.key === '-') {
                  e.preventDefault();
                }
              }}
              InputProps={{
                style: { backgroundColor: 'white' }, // Set white background for TextField
                inputProps: {
                  style: { textAlign: 'center' }, // Center-align the input value
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => handleDelete(equipment)}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        );
      },
    },
    ...phases.map((phase: Phase) => ({
      field: phase.id,
      headerName: phase.name,
      width: 150,
      renderCell: (params: any) => {
        const equipment = params.row.data;
        const phaseId = phase.id;
        const isChecked = params.row.data.phases.includes(phaseId);
        return (
          <Checkbox
            checked={isChecked}
            onChange={() => handleChecked(equipment, phaseId, isChecked)}
          />
        );
      },
    })),
  ];

  let extraColumns: { [key: string]: any } = {};

  ///////////
  // SPECIFIC FOR VIDEO
  ///////////

  const handleConsommationChange = (
    equipment: Video,
    consumptionType: string,
  ) => {
    editEquipment({ ...equipment, consumptionType }, equipmentType);
  };

  extraColumns.consumption = {
    field: 'consommation',
    headerName: 'Consommation',
    width: 150,
    renderCell: (params: any) => {
      const equipment = params.row.data;
      const consumptionType = params.row.consumptionType;
      return (
        <Select
          value={consumptionType}
          onChange={(e) => handleConsommationChange(equipment, e.target.value)}
        >
          <MenuItem value="Moyenne">Moyenne</MenuItem>
          <MenuItem value="Maximale">Maximale</MenuItem>
        </Select>
      );
    },
  };

  ///////////
  // SPECIFIC FOR AMP
  ///////////

  const handleCloseSpeakers = () => setOpenSpeakers(false);

  const handleOpenSpeakers = async (amp: Amp) => {
    await setSelectedEquipment(amp);
    setOpenSpeakers(true);
  };

  const handleValidateSpeakers = async (speakers: Speaker[][], amp: Amp) => {
    const newAmp = { ...amp, speakers };
    editEquipment(newAmp, 'amp');
    setSelectedEquipment(undefined);
  };

  extraColumns.speaker = {
    field: 'speaker',
    headerName: 'Enceintes',
    width: 300,
    renderCell: (params: any) => {
      const amp: Amp = params.row.data;
      const nbSpeakers = amp.speakers.reduce(
        (total, output) =>
          total +
          output.reduce(
            (totalOutput, speaker) => totalOutput + speaker.quantity,
            0,
          ),
        0,
      );
      let text =
        nbSpeakers > 0
          ? nbSpeakers > 1
            ? `${nbSpeakers} enceintes connectées`
            : `${nbSpeakers} enceinte connectée`
          : 'aucune enceinte connectée';

      let ampText: string[] = [];
      if (nbSpeakers > 0) {
        amp.speakers.forEach((output, index) => {
          let outputText: string[] = [];
          output.forEach((speaker) => {
            outputText.push(
              `${speaker.quantity} x ${speaker.data.marque} /  ${speaker.data.ref}`,
            );
          });
          ampText.push(`Canal ${index + 1} (${outputText.join(', ')})`);
        });
      }

      text = text + ' : ' + ampText.join(', ');

      return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', padding: '8px 4px' }}>
          <div style={{ display: 'flex' }}>
            {nbSpeakers === 0 && (
              <div
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: '50%',
                  backgroundColor: 'red',
                  marginRight: 8,
                }}
              ></div>
            )}
            <p
              className="hxr"
              style={{ marginRight: '8px', whiteSpace: 'normal' }}
            >
              {text}
            </p>
          </div>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleOpenSpeakers(amp)}
          >
            Configurer les enceintes
          </Button>
        </div>
      );
    },
  };

  if (config[equipmentType].extraColumns?.length > 0) {
    const equipementExtraColumns = config[equipmentType].extraColumns;
    equipementExtraColumns?.forEach((extraCol) => {
      columns.splice(2, 0, extraColumns[extraCol]);
    });
  }

  const rows = equipments.map((equipment: Equipment, i: number) => {
    let row: any = {
      id: i.toString(),
      rowIndex: i,
      marque: equipment.data.marque,
      ref: equipment.data.ref,
      quantity: equipment.quantity,
      delete: '',
      consumptionType:
        'consumptionType' in equipment ? equipment.consumptionType : '',
      speakers: '',
      data: equipment,
    };
    phases.forEach((phase: Phase) => {
      row[phase.id] = equipment.phases.includes(phase.id);
    });
    return row;
  });

  const getRowClassName = (params: any) => {
    return params.row.rowIndex % 2 === 0 ? 'even-row' : 'odd-row';
  };

  return (
    <div id="equipment_table">
      {selectedEquipment && (
        <ModalSpeakers
          open={openSpeakers}
          onClose={handleCloseSpeakers}
          onValidate={handleValidateSpeakers}
          amp={selectedEquipment}
        />
      )}
      <DataGrid
        rows={rows}
        columns={columns}
        disableColumnMenu={true}
        disableColumnSelector={true}
        getRowHeight={() => 'auto'}
        getRowClassName={getRowClassName}
        components={{
          NoRowsOverlay: () => (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#f9f9f9',
                border: 'none',
              }}
            >
              Aucun équipement à afficher
            </div>
          ),
        }}
      />
    </div>
  );
};

export default EquipmentTable;
