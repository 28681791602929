import { useNavigate, useParams } from 'react-router-dom';

import { Result } from '@showatt/core';

import Text from './Text';

import { TitleBlock } from '../ui/Block';
import Button from '../ui/Button';
import { PageContainer } from '../ui/Container';
import { Abacus } from './ScoreCardComponent';

import { useProject } from '../project/context/useProject';
import { ReactComponent as SkipIcon } from '../ui/icons/skip.svg';

import styled from '@emotion/styled';
import { useQuery } from 'react-query';
import ShowRecommandations from '../show/results/ShowRecommandations';
import ZoneRecommandations from '../show/results/ZoneRecommandations';
import getEquipments from '../show/zone/getEquipments';
import Bar1DComponent, { Bar1DLegend } from './Bar1DComponent';
import BarStacked1DComponent from './BarStacked1DComponent';
import BarStacked2DComponent from './BarStacked2DComponent';
import getShowResultsComplete from './getShowResultsComplete';
import GlobalScoreComponent from './GlobalScore';
import NavComponent from './Nav';
import Pie1DComponent, { Pie1DLegend } from './Pie1DComponent';
import ResultGraph from './ResultGraph';
import SectionTitle, { SectionSubtitle } from './SectionTitle';
import TreemapComponent from './TreemapComponent';

export const ResultsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const CategoryContainer = styled.div`
  padding: 16px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  border-radius: 8px;
`;

export const getResultsByCategories = (results: Result[]) => {
  let resultsByCategories: Result[][] = [];
  let categoryResults: Result[] = [];
  results.forEach((result) => {
    if (result.type === 'title' && categoryResults.length > 0) {
      resultsByCategories.push(categoryResults);
      categoryResults = [result];
    } else {
      categoryResults.push(result);
    }
  });
  resultsByCategories.push(categoryResults);
  return resultsByCategories;
};

const Results = () => {
  const { projectId } = useParams();
  const { project } = useProject(projectId);
  const navigate = useNavigate();
  const { data: db } = useQuery('equipments', () => getEquipments());

  if (!project || !db) {
    return null;
  }

  const results =
    project.model.type === 'show'
      ? getShowResultsComplete(project, db)
      : project.results;

  return (
    <PageContainer noGap>
      <TitleBlock background="var(--results-bg)">
        <h3>{project.name} - Résultats complets</h3>
        <p>Découvrez les impacts détaillés de votre projet ci-dessous</p>
        {project.model.config.actions && (
          <Button
            onClick={() => navigate(`/project/${projectId}/actions`)}
            startIcon={<SkipIcon />}
          >
            Passer à l’action !
          </Button>
        )}
      </TitleBlock>
      <ResultsContent results={results} />
    </PageContainer>
  );
};

export const ResultsContent = ({ results }: { results: Result[] }) => {
  const navIndex = results.findIndex((r: Result) => r.type === 'nav');
  const nav = navIndex > -1 ? results[navIndex] : undefined;
  const finalResults =
    navIndex > -1 ? results.filter((r, i) => i !== navIndex) : results;
  const resultsByCategories = getResultsByCategories(finalResults);
  return (
    <ResultsContainer>
      {nav && <ResultDispatch result={nav} key={'nav'} />}
      {resultsByCategories.map((results: Result[]) => (
        <CategoryContainer>
          {results
            .filter((result: Result) => result.scope === 'complete')
            .map((result: Result, index: number) => (
              <ResultDispatch result={result} key={index} />
            ))}
        </CategoryContainer>
      ))}
    </ResultsContainer>
  );
};

export const ResultDispatch = ({
  result,
  dashboard,
}: {
  result: Result;
  dashboard?: boolean;
}) => {
  switch (result.type) {
    case 'nav':
      return <NavComponent result={result} />;
    case 'title':
      return <SectionTitle result={result} />;
    case 'subtitle':
      return <SectionSubtitle result={result} />;
    case 'text':
      return <Text result={result} />;
    case 'zoneReco':
      return (
        <ZoneRecommandations
          equipment={result.equipment}
          electricitySource={result.electricitySource}
          zoneConsumption={result.zoneConsumption}
        />
      );
    case 'showReco':
      return <ShowRecommandations equipments={result.equipments} />;
    case 'globalScore':
      return (
        <GlobalScoreComponent
          title={result.title!}
          score={result.score}
          dashboard={dashboard}
        >
          <p className="hxr">{result.text}</p>
        </GlobalScoreComponent>
      );
    case 'scoreCard':
      return (
        <GlobalScoreComponent title={result.title!} score={result.score}>
          {result.display! ? (
            <Abacus result={result} visible={result.display!} />
          ) : (
            <p>
              Votre score sera affiché dès que vous aurez répondu aux premières
              questions associées. Il est également possible que vous ne soyez
              pas concerné.
            </p>
          )}
        </GlobalScoreComponent>
      );
    case 'treemap':
      return (
        <ResultGraph
          result={result}
          graph={<TreemapComponent result={result} />}
        />
      );
    case 'pie1D':
      if (dashboard) {
        return <Pie1DComponent result={result} />;
      }
      return (
        <ResultGraph
          result={result}
          graph={<Pie1DComponent result={result} />}
          legend={<Pie1DLegend result={result} />}
        />
      );
    case 'bar1D':
      return (
        <ResultGraph
          result={result}
          graph={<Bar1DComponent result={result} />}
          legend={<Bar1DLegend result={result} />}
        />
      );
    case 'barStacked1D':
      const fullWidth = (result?.data?.data?.length || 0) > 3;
      return (
        <ResultGraph
          result={result}
          fullWidth={fullWidth}
          graph={<BarStacked1DComponent result={result} />}
        />
      );
    case 'barStacked2D':
      return (
        <ResultGraph
          result={result}
          graph={<BarStacked2DComponent result={result} />}
        />
      );
  }
  return null;
};

export default Results;
