import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
} from 'react';

import { Equipment, EquipmentData, Phase, Zone, Zones } from '@showatt/core';
import { electricitySources, zoneTypes } from '../config';
import { reducer, ZoneActionType } from './reducer';
import { sectorColors } from '../config';

interface PhasePartial extends Omit<Phase, 'id' | 'duration' | 'color'> {}

const getInitialState = (zoneId: string, simulationType: string) => {
  return {
    id: zoneId,
    color: sectorColors[parseInt(zoneId.split('_')[1])],
    simulationType,
    name: zoneId.replace('_', ' '),
    type: zoneTypes[0],
    electricitySource: electricitySources[0],
    toCompute: false,
    phases: [],
    equipment: {
      light: [],
      rigg: [],
      video: [],
      amp: [],
      prod: [],
    },
    consumption: 0,
  };
};

export const getHeaderColor = (zones: Zones, name: string, indexSector: number) => {
  if (Object.values(zones).map((zone) => zone.name).includes(name)) {
    const zone = Object.values(zones).filter((zone) => zone.name === name)[0];
    return zone.color;
  }
  else {
      return sectorColors[indexSector];
  }
}

interface ZoneContextType {
  zone?: Zone;
  updateZoneInfo: (key: string, value: string) => void;
  updateZoneBoolean: (key: string, value: boolean) => void;
  addPhase: (phase: PhasePartial) => void;
  editPhase: (phase: Phase) => void;
  deletePhase: (phase: Phase) => void;
  addEquipment: (equipmentData: EquipmentData, equipmentType: string) => void;
  editEquipment: (equipment: Equipment, equipmentType: string) => void;
  deleteEquipment: (equipmentId: string, equipmentType: string) => void;
  updateResults: (zone: Zone) => void;
}

export const ZoneContext = createContext<ZoneContextType>({
  updateZoneInfo: () => {},
  updateZoneBoolean: () => {},
  addPhase: () => {},
  editPhase: () => {},
  deletePhase: () => {},
  addEquipment: () => {},
  editEquipment: () => {},
  deleteEquipment: () => {},
  updateResults: () => {},
});

ZoneContext.displayName = 'ZoneContext';

export const Provider = ({
  children,
  value,
  onChange,
  zoneId,
  simulationType,
}: {
  children: ReactNode;
  value?: Zone;
  onChange?: (zone: Zone) => void;
  zoneId: string;
  simulationType: string;
}) => {
  const [state, dispatch] = useReducer(
    reducer,
    value || getInitialState(zoneId, simulationType),
  );

  useEffect(() => {
    onChange && onChange(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const newValue = {
    zone: state,
    updateZoneInfo: (key?: string, value?: string) => {
      dispatch({ type: ZoneActionType.UPDATE_INFO, payload: { key, value } });
    },
    updateZoneBoolean: (key?: string, value?: boolean) => {
      dispatch({
        type: ZoneActionType.UPDATE_BOOLEAN,
        payload: { key, value },
      });
    },
    addPhase: (phase?: PhasePartial) => {
      dispatch({ type: ZoneActionType.ADD_PHASE, payload: { phase } });
    },
    editPhase: (phase?: Phase) => {
      dispatch({ type: ZoneActionType.EDIT_PHASE, payload: { phase } });
    },
    deletePhase: (phase?: Phase) => {
      dispatch({ type: ZoneActionType.DELETE_PHASE, payload: { phase } });
    },
    addEquipment: (equipmentData?: EquipmentData, equipmentType?: string) => {
      dispatch({
        type: ZoneActionType.ADD_EQUIPMENT,
        payload: { equipmentData, equipmentType },
      });
    },
    editEquipment: (equipment?: Equipment, equipmentType?: string) => {
      dispatch({
        type: ZoneActionType.EDIT_EQUIPMENT,
        payload: { equipment, equipmentType },
      });
    },
    deleteEquipment: (equipmentId?: string, equipmentType?: string) => {
      dispatch({
        type: ZoneActionType.DELETE_EQUIPMENT,
        payload: { equipmentId, equipmentType },
      });
    },
    updateResults: (zone?: Zone) => {
      dispatch({
        type: ZoneActionType.UPDATE_RESULTS,
        payload: { zone },
      });
    },
  };

  return (
    <ZoneContext.Provider value={newValue}>{children}</ZoneContext.Provider>
  );
};

const useZone = () => useContext(ZoneContext);

export default useZone;
