import styled from '@emotion/styled';
import { ReactComponent as Add } from '../ui/icons/add.svg';

const IconContainer = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
`;

const NewReportButtonDashboardContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 4px;
`;

const NewReportButtonDashboard = ({
  onClick,
}: {
  onClick?: (model: string) => void;
}) => {
  return (
    <NewReportButtonDashboardContainer>
      <IconContainer>
        <Add />
      </IconContainer>
      <p className="hxb">Rapport</p>
    </NewReportButtonDashboardContainer>
  );
};

export default NewReportButtonDashboard;
