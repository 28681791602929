import styled from '@emotion/styled';
import { Indicator as IndicatorType } from '@showatt/core';

interface IndicatorProps {
  indicator: IndicatorType;
}

const IndicatorContainer = styled('p')`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  > p.title {
    font-size: 10px;
  }
  .indicator {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 11px 0;
    gap: 4px;
    align-items: center;
    height: 46px;
    p {
      font-weight: 500;
      font-size: 24px;
    }
    span {
      font-size: 14px;
      font-weight: 400;
      align-self: flex-end;
    }
  }
`;

const Indicator = ({ indicator }: IndicatorProps) => {
  const { title, unit, number, color } = indicator;
  return (
    <IndicatorContainer>
      <p className="title">{title}</p>
      <div className="indicator" style={{ backgroundColor: color }}>
        <p>{number}</p>
        <span>{unit}</span>
      </div>
    </IndicatorContainer>
  );
};

export default Indicator;
