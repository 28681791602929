import styled from '@emotion/styled';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import AppLayout from '../layout/AppLayout';
import { HeaderProjects as Header } from '../layout/Headers';
import useModels from '../project/context/useModels';
import { SortButton } from '../ui/Button';
import { ReactComponent as DateIcon } from '../ui/icons/date.svg';
import { ReactComponent as Flag } from '../ui/icons/flag.svg';
import { ReactComponent as Sort } from '../ui/icons/sort.svg';
import Loader from '../ui/Loader';
import ModelBlock from './blocks/model';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  flex-grow: 1;
  gap: 20px;
  max-width: 1400px;
  width: 100%;
`;

const SortContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 24px;
  > div:first-of-type {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;
const ProjectTypeList = () => {
  const { type } = useParams();
  const [sortOrder, setSortOrder] = useState<'date' | 'completion'>('date');

  const { models, loading } = useModels();

  const model = models?.find((model) => model.type === type);

  if (!model) {
    return null;
  }

  if (!models && loading) {
    return <Loader fullPage />;
  }

  return (
    <AppLayout header={<Header />}>
      <Helmet>
        <title>{`SHOWATT`}</title>
      </Helmet>
      {!models && loading && <Loader fullPage />}
      {models && !loading && (
        <Layout>
          <SortContainer>
            <div>
              <Sort />
              <p>Trier par :</p>
            </div>
            <SortButton
              startIcon={<DateIcon />}
              className={sortOrder === 'date' ? 'selected' : ''}
              onClick={() => setSortOrder('date')}
            >
              Date de création
            </SortButton>
            <SortButton
              startIcon={<Flag />}
              className={sortOrder === 'completion' ? 'selected' : ''}
              onClick={() => setSortOrder('completion')}
            >
              Taux de remplissage
            </SortButton>
          </SortContainer>
          <ModelBlock model={model} sortOrder={sortOrder} />
        </Layout>
      )}
    </AppLayout>
  );
};

export default ProjectTypeList;
