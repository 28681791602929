import styled from '@emotion/styled';
import { IdParameterInput, Parameter, Zone, Zones } from '@showatt/core';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getProjectParameter, useProject } from '../context/useProject';

import { Delete, Edit, FileCopy } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

const Container = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  > * {
    flex: 1;
  }
`;

const DescriptionContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  div {
    display: flex;
    align-items: center;
  }
`;

const ButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

interface ZoneParameterProps {
  onChange: (name: string) => void;
  parameter: Parameter;
}

const ZoneParameter = ({ onChange, parameter }: ZoneParameterProps) => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { project, updateParameterAsync, updateZones } = useProject(projectId);
  useEffect(() => {
    if (project && parameter.id && parameter.id in project?.zones) {
      const newName = project?.zones[parameter.id].name;
      onChange(newName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!project) return null;
  const zones = project.zones;
  const zone =
    zones &&
    Object.values(zones).length > 0 &&
    parameter.id &&
    parameter.id in zones
      ? zones[parameter.id]
      : undefined;

  const nbEquipments = zone
    ? Object.values(zone.equipment).reduce(
        (total, equipment) => total + equipment.length,
        0,
      )
    : 0;
  const takenInAccountMsg =
    zone && zone.toCompute
      ? 'Zone prise en compte dans le total'
      : 'Zone non prise en compte dans le total';
  const takeInAcountColor = zone && zone.toCompute ? 'green' : 'red';

  const handleDuplicateZone = async (zone: Zone | undefined) => {
    const nbZones = getProjectParameter(project, 'Zone_number')?.value;
    if (typeof nbZones == 'number') {
      if (zone) {
        const newZone = getDuplicateZone(zone, nbZones);
        const keyOfNewZone = `Zone_${nbZones + 1}`;
        await Promise.all([
          updateParameterAsync([
            {
              type: 'id',
              id: 'Zone_number',
              value: nbZones + 1,
            },
          ]),
          updateZones({
            ...project.zones,
            [keyOfNewZone]: newZone,
          }),
        ]);
      } else {
        const zoneId = `Zone_${nbZones + 1}`;
        const newZones: Zones = { ...project.zones };
        if (zoneId in newZones) delete newZones[zoneId]; //
        const zoneNameUpdate: IdParameterInput = {
          type: 'id',
          id: zoneId,
          value: `${parameter.value} (copie)`,
        };
        await Promise.all([
          updateParameterAsync([
            {
              type: 'id',
              id: 'Zone_number',
              value: nbZones + 1,
            },
            zoneNameUpdate,
          ]),
          updateZones(newZones),
        ]);
      }
    }
    return null;
  };

  const handleDeleteZone = async (zoneId: string | undefined) => {
    const nbZones = getProjectParameter(project, 'Zone_number')?.value;
    if (typeof nbZones == 'number' && zoneId && zoneId.length > 0) {
      const updateZoneNumber: IdParameterInput = {
        type: 'id',
        id: 'Zone_number',
        value: nbZones - 1,
      };
      const { updates, newZones } = getUpdatesFromDeletion(
        project.zones,
        zoneId,
      );
      await Promise.all([
        updateParameterAsync([updateZoneNumber, ...updates]),
        updateZones(newZones),
      ]);
    }
  };

  return (
    <Container id={zone?.id}>
      {zone ? (
        <DescriptionContainer>
          <p className="h6r">
            {zone.phases.length} phase(s), {nbEquipments} équipement(s)
          </p>
          <div>
            <div
              style={{
                width: 20,
                height: 20,
                borderRadius: '50%',
                backgroundColor: takeInAcountColor,
                marginRight: 8,
              }}
            ></div>
            <p className="h6r">{takenInAccountMsg} </p>
          </div>
        </DescriptionContainer>
      ) : (
        <DescriptionContainer>
          <div>
            <div
              style={{
                width: 20,
                height: 20,
                borderRadius: '50%',
                backgroundColor: takeInAcountColor,
                marginRight: 8,
              }}
            ></div>
            <p className="h6r">Zone non configurée</p>
          </div>
        </DescriptionContainer>
      )}
      <ButtonContainer>
        <Tooltip title="Configurer la zone" placement="top">
          <IconButton
            onClick={() =>
              navigate(`/project/${projectId}/zone/${parameter.id}`)
            }
            color="primary"
            size="large"
          >
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Dupliquer la zone" placement="top">
          <IconButton
            onClick={() => handleDuplicateZone(zone)}
            color="primary"
            size="large"
          >
            <FileCopy />
          </IconButton>
        </Tooltip>
        <Tooltip title="Supprimer la zone" placement="top">
          <IconButton
            onClick={() => handleDeleteZone(parameter.id)}
            color="primary"
            size="large"
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </ButtonContainer>
    </Container>
  );
};

const getDuplicateZone = (zone: Zone, nbZones: number) => {
  return {
    ...zone,
    id: `Zone_${nbZones + 1}`,
    name: `${zone.name} (copie)`,
  };
};

const getUpdatesFromDeletion = (zones: Zones, zoneId: string) => {
  const id = parseInt(zoneId.split('_')[1]);
  let updates: IdParameterInput[] = [];
  const newZones = { ...zones };
  if (typeof id == 'number') {
    if (zoneId in newZones) delete newZones[zoneId];
    for (let i = id; i <= 12; i++) {
      const zoneIndex = `Zone_${i}`;
      const upperZoneIndex = `Zone_${i + 1}`;
      const isUpperZone = upperZoneIndex in zones;
      const name = isUpperZone ? zones[upperZoneIndex].name : `Zone ${i}`;
      updates.push({
        type: 'id',
        id: zoneIndex,
        value: name,
      });
      if (isUpperZone) {
        newZones[zoneIndex] = {
          ...newZones[upperZoneIndex],
          id: zoneIndex,
        };
        delete newZones[upperZoneIndex];
      }
    }
  }
  return { updates, newZones };
};

export default ZoneParameter;
