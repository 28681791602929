import styled from '@emotion/styled';

export const BlockContainer = styled.div`
  background-color: ${({ color }) => color};
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  align-items: stretch;
  border-radius: 16px;
  flex-grow: 1;
`;

export const BlockHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
}
`;

export const BlockContent = styled.div`
  // background-color: ${({ color }) => color};
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  overflow: hidden;
  flex-grow: 1;
  flex-flow: row wrap;
  align-content: flex-start;
`;
