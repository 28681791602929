export const zoneTabs = [
  'Phases',
  'Son',
  'Vidéo',
  'Lumière',
  'Levage',
  'Régie et production',
];

export const equipmentNames: { [key: string]: string } = {
  rigg: 'Levage',
  video: 'Vidéo',
  light: 'Lumière',
  amp: 'Son',
  prod: 'Régie et production',
  speaker: 'Enceinte',
};

export const equipmentTypeColors: { [key: string]: string } = {
  light: '#2d79a6',
  rigg: '#d9623f',
  video: '#c6def1',
  amp: '#81efc8',
  prod: '#FFE66D',
  speaker: '#55bf80',
};

export const phaseValues: { [key: string]: string[] } = {
  Scène: ['Montage', 'Balance', 'Show', 'Démontage'],
  'Loges / backstage': ['Montage', 'Balance', 'Show', 'Démontage'],
  Catering: ['Préparation des repas', 'Service', 'Montage', 'Démontage'],
  'Restauration public': [
    'Préparation des repas',
    'Service',
    'Montage',
    'Démontage',
  ],
  'Bureaux de production': ['Pré-prod / montage', 'Exploitation', 'Démontage'],
  Exposants: ['Pré-prod / montage', 'Exploitation', 'Démontage'],
  'Espace public': ['Pré-prod / montage', 'Exploitation', 'Démontage'],
  'Caisse / Billetterie': ['Pré-prod / montage', 'Exploitation', 'Démontage'],
};

export const zoneIcons: { [key: string]: string } = {
  Scène: 'scene',
  'Loges / backstage': 'backstage',
  Catering: 'catering',
  'Restauration public': 'food',
  'Bureaux de production': 'office',
  Exposants: 'exhibition',
  'Espace public': 'public',
  'Caisse / Billetterie': 'ticket'
};

export type Weights = {
  Montage: number;
  Balance: number;
  Show: number;
  Démontage: number;
  'Préparation des repas': number;
  Service: number;
  'Pré-prod / montage': number;
  Exploitation: number;
};

export const phaseTypeWeight = {
  light: {
    Montage: 0.7,
    Balance: 0.7,
    Show: 0.9,
    Démontage: 0.7,
    'Préparation des repas': 1,
    Service: 1,
    'Pré-prod / montage': 1,
    Exploitation: 1,
  },
  prod: {
    Montage: 1,
    Balance: 1,
    Show: 1,
    Démontage: 1,
    'Préparation des repas': 1,
    Service: 1,
    'Pré-prod / montage': 1,
    Exploitation: 1,
  },
  rigg: {
    Montage: 1,
    Balance: 1,
    Show: 1,
    Démontage: 1,
    'Préparation des repas': 1,
    Service: 1,
    'Pré-prod / montage': 1,
    Exploitation: 1,
  },
  video: {
    Montage: 1,
    Balance: 1,
    Show: 1,
    Démontage: 1,
    'Préparation des repas': 1,
    Service: 1,
    'Pré-prod / montage': 1,
    Exploitation: 1,
  },
  amp: {
    Montage: 1,
    Balance: 0.8,
    Show: 1,
    Démontage: 1,
    'Préparation des repas': 1,
    Service: 1,
    'Pré-prod / montage': 1,
    Exploitation: 1,
  },
};

export const zoneTypes = Object.keys(phaseValues);

export type electricitySourceType = {
  'Réseau RTE': any;
  'Groupe électrogène Gasoil': any;
  'Groupe électrogène BiOil': any;
  'Parc batteries + panneaux solaires': any;
};

export const electricitySourcesData: electricitySourceType = {
  'Réseau RTE': {
    FE: 0.05,
  },
  'Groupe électrogène Gasoil': {
    FE: 1.65,
  },
  'Groupe électrogène BiOil': {
    FE: 0.3,
  },
  'Parc batteries + panneaux solaires': {
    FE: 0.2,
  },
};

const pastelColors = [
  '#f1c4d4',
  '#f7e9af',
  '#c5e6c7',
  '#bee1e6',
  '#c6def1',
  '#dbcdf0',
  '#f1c8e0',
  '#ffddcc',
  '#ffd5b1',
  '#d4d4d4',
];

export const getPhaseColor = (phasesColors: string[]) => {
  for (const color of pastelColors) {
    if (!phasesColors.includes(color)) {
      return color;
    }
  }
  return '#000000'; // Replace with your default color
};

export const sectorColors = [
  '#b5b5b5',
  '#a55e47',
  '#ffa9c4',
  '#ff5b17',
  '#ff9229',
  '#8746ae',
  '#cea8ff',
  '#0b67a9',
  '#afd4ff',
  '#0f9324',
  '#81efc8',
];

export const electricitySources = Object.keys(electricitySourcesData);

const getHours = () => {
  const hoursArray = [];
  for (let hour = 0; hour <= 23; hour++) {
    const formattedHour = hour.toString().padStart(2, '0');
    hoursArray.push(formattedHour + 'h00');
  }
  return hoursArray;
};

export const hours = getHours();

export interface substitutionConditionType {
  property_name: string;
  condition_type: string;
  name_displayed: string;
  tolerance?: number;
  description?: string;
  function?: (propertyToSubstitute: string, propertyTest: string) => boolean;
}

interface substitutionConditionsType {
  [key: string]: substitutionConditionType[];
}

const allowedLightTypeReplacements = [
  ['LED fixe', 'LED sur batterie'],
  ['LED sur batterie', 'LED fixe'],
  ['Traditionnel', 'LED fixe'],
  ['Traditionnel', 'LED sur batterie'],
];

// TO BE CONSISTENT WITH THE OBJECT DEFINED SERVER SIDE
export const substitutionConditions: substitutionConditionsType = {
  light: [
    {
      property_name: 'type',
      name_displayed: 'Type',
      condition_type: 'function',
      description: `"Led fixe" peut être remplacé par "led sur batterie" et inversement et "trad" peut être remplacé par "led fixe" ou par "led sur batterie" mais pas l'inverse`,
      function: (propertyToSubstitute: string, propertyTest: string) => {
        if (propertyToSubstitute === propertyTest) {
          return true;
        }
        return allowedLightTypeReplacements.some((allowedReplacement) => {
          if (
            allowedReplacement[0].toLowerCase() ===
              propertyTest.toLowerCase() &&
            allowedReplacement[1].toLowerCase() ===
              propertyToSubstitute.toLowerCase()
          ) {
            return true;
          }
          return false;
        });
      },
    },
    {
      property_name: 'power',
      name_displayed: 'Puissance',
      condition_type: 'inferior',
    },
    {
      property_name: 'candlepower',
      name_displayed: 'Candlepower',
      condition_type: 'tolerance',
      tolerance: 0.5,
    },
    {
      property_name: 'zoomRangeMin',
      name_displayed: 'Zoom Range Min',
      condition_type: 'tolerance',
      tolerance: 0.2,
    },
    {
      property_name: 'zoomRangeMax',
      name_displayed: 'Zoom Range Max',
      condition_type: 'tolerance',
      tolerance: 0.2,
    },
    {
      property_name: 'temperatureMin',
      name_displayed: 'Temperature Min',
      condition_type: 'tolerance',
      tolerance: 0.2,
    },
    {
      property_name: 'temperatureMax',
      name_displayed: 'Temperature Max',
      condition_type: 'tolerance',
      tolerance: 0.2,
    },
    {
      property_name: 'colorProfile',
      name_displayed: 'Profil de couleur',
      condition_type: 'include',
    },
    {
      property_name: 'gobo',
      name_displayed: 'Gobo',
      condition_type: 'equal',
    },
    {
      property_name: 'nbGobo',
      name_displayed: 'Nb Gobo',
      condition_type: 'tolerance',
      tolerance: 0.3,
    },
    {
      property_name: 'connectors',
      name_displayed: 'Connecteurs',
      condition_type: 'include',
    },
    {
      property_name: 'irc',
      name_displayed: 'IRC',
      condition_type: 'superior or equal',
    },
  ],
  rigg: [
    {
      property_name: 'power',
      name_displayed: 'Puissance',
      condition_type: 'inferior',
    },
    {
      property_name: 'charge',
      name_displayed: 'Charge',
      condition_type: 'superior or equal',
    },
    {
      property_name: 'speed',
      name_displayed: 'Vitesse',
      condition_type: 'equal',
    },
  ],
  video: [
    {
      property_name: 'consom_moy',
      name_displayed: 'Puissance moyenne',
      condition_type: 'inferior',
    },
    {
      property_name: 'consom_max',
      name_displayed: 'Puissance max',
      condition_type: 'inferior',
    },
    {
      property_name: 'indoor',
      name_displayed: 'Intérieur',
      condition_type: 'equal',
    },
    {
      property_name: 'outdoor',
      name_displayed: 'Extérieur',
      condition_type: 'equal',
    },
    {
      property_name: 'brightness',
      name_displayed: 'Luminosité',
      condition_type: 'superior or equal',
    },
    {
      property_name: 'pitch',
      name_displayed: 'Pitch',
      condition_type: 'superior or equal',
    },
  ],
  prod: [
    {
      property_name: 'power',
      name_displayed: 'Puissance',
      condition_type: 'inferior',
    },
    {
      property_name: 'classe',
      name_displayed: 'Classe',
      condition_type: 'equal',
    },
    {
      property_name: 'type',
      name_displayed: 'Type',
      condition_type: 'equal',
    },
  ],
  amp: [
    {
      property_name: 'marque',
      name_displayed: 'Marque',
      condition_type: 'equal',
    },
    {
      property_name: 'classe',
      name_displayed: 'Classe',
      condition_type: 'equal',
    },
    {
      property_name: 'type',
      name_displayed: 'Type',
      condition_type: 'equal',
    },
    {
      property_name: 'inputPowerMax',
      name_displayed: 'Input Power Max',
      condition_type: 'inferior',
    },
    {
      property_name: 'amperage',
      name_displayed: 'Ampérage',
      condition_type: 'inferior',
    },
    {
      property_name: 'nbOutput',
      name_displayed: 'Nb canaux sortie',
      condition_type: 'equal',
    },
    {
      property_name: 'outputPowerPerChannel',
      name_displayed: 'Output power per channel',
      condition_type: 'superior or equal',
    },
  ],
  speaker: [],
};
