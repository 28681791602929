import {
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

interface ListSelectProps {
  value: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  label: string;
  name?: string;
  options: string[];
}

const ListSelect = ({
  value,
  onChange,
  label,
  options,
  name,
}: ListSelectProps) => {
  return (
    <>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        onChange={onChange}
        label={label}
        value={value}
        name={name}
        fullWidth
      >
        {(options || []).map((option) => (
          <MenuItem key={option} value={option}>
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default ListSelect;
