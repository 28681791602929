import {
  Equipment,
  EquipmentDBProps,
  EquipmentList,
  EquipmentWithZone,
  Project,
  Result,
  ShowReco,
  Title,
  Zone,
  Zones,
} from '@showatt/core';

import getZoneComplete from '../show/results/getZoneComplete';

const getShowResultsComplete = (project: Project, db: EquipmentDBProps) => {
  const { results } = project;
  const equipmentsReco: ShowReco = {
    type: 'showReco',
    scope: 'complete',
    equipments: getEquipmentsReco(project.zones, db),
  };
  const zonesResults = getZonesResults(project.zones);
  const showResults: Result[] = [
    ...results.filter((r) => r.scope === 'complete'),
    equipmentsReco,
    ...zonesResults,
  ];
  return showResults;
};

const getEquipmentsReco = (zones: Zones, db: EquipmentDBProps) => {
  const zonesComplete = Object.values(zones).map((zone) =>
    getZoneComplete(zone, db),
  );
  return getBestReco(zonesComplete);
};

const getZonesResults = (zones: Zones) => {
  return Object.values(zones)
    .filter((zone: Zone) => zone.toCompute)
    .reduce((results: Result[], zone: Zone, index: number) => {
      if (zone.resultsComplete) {
        const zoneResultsComplete = [...zone.resultsComplete];
        const zoneName = `Zone ${index + 1} : "${zone.name}"`;
        const titleIndex = zoneResultsComplete.findIndex(
          (r) => r.type === 'title',
        );
        if (titleIndex > -1) {
          (zoneResultsComplete[titleIndex] as Title).text = zoneName;
        }
        return [...results, ...(zoneResultsComplete as Result[])];
      }
      return results;
    }, []);
};

const getBestReco = (zones: Zone[]) => {
  const equipmentList: EquipmentWithZone[] = [];
  for (const zone of zones) {
    Object.keys(zone.equipment).forEach((equipmentType) => {
      const equipments = zone.equipment[equipmentType as keyof EquipmentList];
      for (const equipment of equipments) {
        if (equipment.maxReduction != null) {
          const equipmentWithZone: EquipmentWithZone = {
            ...equipment,
            zone: zone.name,
          };
          equipmentList.push(equipmentWithZone);
        }
      }
    });
  }
  equipmentList.sort((a, b) => b.maxReduction! - a.maxReduction!);
  const bestReco = equipmentList
    .filter((eq) => eq.substitutes && eq.substitutes.length > 0)
    .slice(0, 5);

  for (const reco of bestReco) {
    if (reco.substitutes) {
      const substituteWithLowestConsumption = reco.substitutes.reduce(
        (prev: Equipment, current: Equipment) => {
          if (
            prev.consumption === null ||
            (current.consumption !== null &&
              current.consumption < prev.consumption)
          ) {
            return current;
          }
          return prev;
        },
      );

      reco.substitutes = [substituteWithLowestConsumption];
    }
  }
  return bestReco;
};

export default getShowResultsComplete;
