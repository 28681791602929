import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import CalculatorLayout from '../../layout/CalculatorLayout';
import {
  getProjectParameter,
  useProject,
} from '../../project/context/useProject';
import { Provider as ZoneProvider } from './context/useZone';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { Zone as ZoneType, Zones } from '@showatt/core';
import ZoneResults from '../results/ZoneResults';
import useZone from './context/useZone';
import ZoneHeader from './ZoneHeader';
import ZonePhases from './ZonePhases';

import Equipment from './Equipment';

import { zoneTabs } from './config';

const NavContainer = styled('div')`
  width: 100%;
  display: flex;
  gap: 16px;
  > div:first-of-type {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
  }
`;

const Content = styled('div')`
  height: calc(100% - 16px);
  width: 100%;
  background-color: white;
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  tabSelected: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, tabSelected, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={tabSelected !== index}
      id={`simple-tabpanel-${index}`}
      key={`${index}`}
    >
      {children}
    </div>
  );
};

const getZoneTabLabel = (zone: ZoneType, label: string) => {
  switch (label) {
    case 'Phases':
      return `${label} (${zone.phases.length})`;
    case 'Son':
      return `${label} (${zone.equipment.amp.length})`;
    case 'Vidéo':
      return `${label} (${zone.equipment.video.length})`;
    case 'Régie et production':
      return `${label} (${zone.equipment.prod.length})`;
    case 'Lumière':
      return `${label} (${zone.equipment.light.length})`;
    case 'Levage':
      return `${label} (${zone.equipment.rigg.length})`;
    default:
      return '';
  }
};

const Zone = () => {
  const { zone } = useZone();
  const { projectId } = useParams();
  const { project } = useProject(projectId);
  const [tabSelected, setTabSelected] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabSelected(newValue);
  };
  if (!zone || !project) return null;
  return (
    <CalculatorLayout
      useLocalStorage={true}
      header={
        <NavContainer>
          <div>
            <ZoneHeader zone={zone} projectName={project.name} />
            <ZoneTabs
              onChange={handleChange}
              tabSelected={tabSelected}
              zone={zone}
            />
          </div>
        </NavContainer>
      }
      content={
        <Content>
          <TabPanel tabSelected={tabSelected} index={0}>
            <ZonePhases />
          </TabPanel>
          <TabPanel tabSelected={tabSelected} index={1}>
            {tabSelected === 1 && <Equipment equipmentType="amp" />}
          </TabPanel>
          <TabPanel tabSelected={tabSelected} index={2}>
            {tabSelected === 2 && <Equipment equipmentType="video" />}
          </TabPanel>
          <TabPanel tabSelected={tabSelected} index={3}>
            {tabSelected === 3 && <Equipment equipmentType="light" />}
          </TabPanel>
          <TabPanel tabSelected={tabSelected} index={4}>
            {tabSelected === 4 && <Equipment equipmentType="rigg" />}
          </TabPanel>
          <TabPanel tabSelected={tabSelected} index={5}>
            {tabSelected === 5 && <Equipment equipmentType="prod" />}
          </TabPanel>
        </Content>
      }
      results={<ZoneResults project={project} />}
    />
  );
};

interface ZoneTabsProps {
  onChange: (event: React.SyntheticEvent, value: number) => void;
  tabSelected: number;
  zone: ZoneType;
}

const ZoneTabs = ({ onChange, tabSelected, zone }: ZoneTabsProps) => {
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        padding: '16px',
      }}
    >
      <Tabs
        value={tabSelected}
        onChange={onChange}
        sx={{ display: 'flex', gap: '8px' }}
      >
        {zoneTabs.map((zoneTab: string, index: number) => (
          <Tab
            label={getZoneTabLabel(zone, zoneTab)}
            id={`tab_${index}`}
            key={`tab_${index}`}
            sx={{
              color: 'black',
              fontSize: '18px',
              '&.Mui-selected': {
                color: 'black',
                fontWeight: 'bold',
                backgroundColor: 'var(--color-primary)',
              },
              '&:hover': {
                backgroundColor: 'var(--color-primary-light)',
              },
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
};

const ZoneWithProvider = () => {
  const { projectId, zoneId } = useParams();
  const { project, updateZones } = useProject(projectId);
  if (!project || !zoneId) {
    return null;
  }
  const handleOnZoneChanged = (zone: ZoneType) => {
    if (JSON.stringify(project?.zones[zoneId]) !== JSON.stringify(zone)) {
      let zones: Zones = { ...project.zones };
      zones[zoneId] = zone;
      updateZones(zones);
    }
  };
  const simulationType =
    getProjectParameter(project, 'Simulation_type')?.value?.toString() || '';
  return (
    <ZoneProvider
      value={zoneId in project.zones ? project?.zones[zoneId] : undefined}
      onChange={handleOnZoneChanged}
      zoneId={zoneId}
      simulationType={simulationType}
    >
      <Zone />
    </ZoneProvider>
  );
};

export default ZoneWithProvider;
