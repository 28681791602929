import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import AppLayout from '../layout/AppLayout';
import { HeaderProjects as Header } from '../layout/Headers';
import useModels from '../project/context/useModels';
import Loader from '../ui/Loader';
import ModelBlock from './blocks/model';
import useConfiguration from '../useConfiguration';
const Layout = styled.div`
  padding: 16px;
  display: flex;
  min-height: 100%;
  flex-grow: 1;
  gap: 16px;
  max-width: 1400px;
  width: 100%;
  > div:first-of-type {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  > div:last-of-type {
    flex: 1;
  }
`;

const Dashboard = () => {
  const { models, loading } = useModels();

  // const buildingModel = models?.find((model) => model.type === 'building');
  const showModel = models?.find((model) => model.type === 'show');

  return (
    <AppLayout header={<Header />}>
      <Helmet>
        <title>{`SHOWATT`}</title>
      </Helmet>
      {!models && loading && <Loader fullPage />}
      {models && !loading && (
        <Layout>
          {showModel && <ModelBlock model={showModel} />}
          {/* <div>
            <Top>
              {buildingModel && <ModelBlock model={buildingModel} />}
              <TopLeft>
              <ReportBlock />
            </TopLeft>
            </Top>
            <Bottom></Bottom>
          </div> */}
          <Introduction />
        </Layout>
      )}
    </AppLayout>
  );
};

const IntroductionContainer = styled.div`
  background-color: lightgrey;
  border-radius: 16px;
  padding: 32px;
  p {
    margin: 24px 0;
  }
  .h3b {
    margin-top: 0;
    margin-bottom: 32px;
  }
  ul {
    margin-left: 32px;
    margin-top: -16px;
  }
`;

const Introduction = () => {
  const { configuration } = useConfiguration();
  return (
    <IntroductionContainer>
      <div dangerouslySetInnerHTML={{
              __html: configuration['dashboard.welcomeMessage'],
            }} />
    </IntroductionContainer>
  );
};

export default Dashboard;
