import styled from '@emotion/styled';
import { Model, ParameterInput, Project } from '@showatt/core';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useProjects from '../../../project/context/useProjects';
import ProjectCard from '../../../project/ProjectCard';
import { sortByDate } from '../../../project/ProjectsUtils';
import { BlockContainer, BlockContent, BlockHeader } from '../../ui';
import CreateProjectModal from './CreateProjectModal';
import Empty from './Empty';
import NewProjectButtonDashboard from './NewProjectButtonDashboard';

const NewProjectBlock = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed #000000;
  border-radius: 8px;
  gap: 16px;
  height: 150px;
  min-width: 200px;
  cursor: pointer;

  &:hover {
    border: 1px solid #000000;
    cursor: pointer;
    p {
      text-decoration: underline;
    }
  }
`;

interface ModelBlockProps {
  model: Model;
  sortOrder?: string;
}

const ModelBlock = ({ model, sortOrder }: ModelBlockProps) => {
  const { projects, remove } = useProjects({
    projectType: model.type,
  });
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  if (!projects) {
    return <Empty model={model} />;
  }

  const sortedProjects = projects!.sort((a: Project, b: Project) => {
    if (sortOrder === 'date') {
      return sortByDate(a, b);
    }
    return a.completionRate - b.completionRate;
  });

  const handleSave = (values: ParameterInput[], modelType: string) => {
    const encodedValues = encodeURIComponent(JSON.stringify(values));
    const encodedModelType = encodeURIComponent(modelType);
    navigate(`/project?values=${encodedValues}&type=${encodedModelType}`);
  };

  const handleDelete = (id: string) => async (event: any) => {
    return await remove(id);
  };

  const handleNewProjectClicked = () => {
    setOpen(true);
  };

  return (
    <>
      {open && (
        <CreateProjectModal
          open={open}
          setOpen={setOpen}
          onSave={handleSave}
          model={model}
        />
      )}
      <BlockContainer color={model.color}>
        <BlockHeader>
          <Link to={`/projects/${model.type}`} className="h3b">
            {model.name} ({projects.length})
          </Link>
        </BlockHeader>
        <BlockContent color={model.color}>
          {projects?.length === 0 && (
            <Empty model={model} onNewProject={handleNewProjectClicked} />
          )}
          {projects?.length > 0 && (
            <>
              <NewProjectBlock onClick={handleNewProjectClicked}>
                <NewProjectButtonDashboard model={model.singularName} />
              </NewProjectBlock>
              {sortedProjects?.map((project: Project) => (
                <ProjectCard
                  key={project._id.toString()}
                  project={project}
                  onClick={() => navigate(`/project/${project._id}`)}
                  onDelete={handleDelete(project._id.toString())}
                />
              ))}
            </>
          )}
        </BlockContent>
      </BlockContainer>
    </>
  );
};

export default ModelBlock;
