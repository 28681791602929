import styled from '@emotion/styled';

export const Container = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  height: 100%;
`;

export const ResultSection = styled('div')`
  width: 100%;
  background-color: white;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Title = styled('p')`
  font-weight: bold;
  font-size: 18px;
`;

export const IndicatorsContainer = styled('p')`
  display: flex;
  gap: 8px;
`;

export const GraphContainer = styled('div')`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  > * {
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
  }
`;
