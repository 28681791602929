import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextField,
} from '@mui/material';
import * as yup from 'yup';

import { IndexParameterInput } from '@showatt/core';
import { useFormik } from 'formik';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useProjects from '../project/context/useProjects';
import Button from '../ui/Button';
import useReports from './context/useReports';

interface CreateReportModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose?: any;
  onSave?: (values: IndexParameterInput[], modelType: string) => void;
}

const validationSchema = yup.object({
  name: yup.string().required('Champ obligatoire'),
  projects: yup.array().required('Champ obligatoire'),
});

const CreateReportModal = ({
  open,
  setOpen,
  onSave,
  onClose,
}: CreateReportModalProps) => {
  const navigate = useNavigate();
  const called = useRef(false);
  const autofocusFirst = useRef(true);
  const { create } = useReports();
  const { projects } = useProjects();

  const formik = useFormik({
    initialValues: {
      name: '',
      projects: projects?.map((p) => p._id.toString()) as string[],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const result = await create({
        name: values.name,
        projectIds: values.projects,
      });
      handleClose();
      navigate(`/reports/${result._id}`);
    },
  });

  const handleClose = () => {
    called.current = false;
    autofocusFirst.current = true;
    onClose && onClose();
    setOpen(false);
  };

  const handleCancel = () => {
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} scroll="paper" id="create_modal">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="scroll-dialog-title">Création Rapport</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
            component="div"
          >
            <TextField
              id="name"
              name="name"
              label="Nom"
              autoFocus
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              fullWidth
            />

            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <FormLabel component="legend">
                Projets à prendre en compte
              </FormLabel>
              <FormGroup>
                {projects?.map((project) => (
                  <FormControlLabel
                    key={project._id.toString()}
                    control={
                      <Checkbox
                        checked={formik.values.projects.includes(
                          project._id.toString(),
                        )}
                        onChange={formik.handleChange}
                        value={project._id.toString()}
                        name="projects"
                      />
                    }
                    label={`${project.model.singularName} - ${project.name}`}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{ position: 'sticky', bottom: 0, background: 'white' }}
        >
          <Button onClick={handleCancel}>Annuler</Button>
          <Button type="submit" variant="contained" color="primary">
            Créer le rapport
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateReportModal;
