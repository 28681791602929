import { Equipment, EquipmentList, Phase, Zone } from '@showatt/core';
import {
  getEquipmentPower,
  getPhaseDuration,
  getZoneData,
} from '../../results/getZonesResults';
import { getPhaseColor } from '../config';

export enum ZoneActionType {
  UPDATE_INFO = 'UPDATE_INFO',
  UPDATE_BOOLEAN = 'UPDATE_BOOLEAN',
  ADD_PHASE = 'ADD_PHASE',
  EDIT_PHASE = 'EDIT_PHASE',
  DELETE_PHASE = 'DELETE_PHASE',
  ADD_EQUIPMENT = 'ADD_EQUIPMENT',
  EDIT_EQUIPMENT = 'EDIT_EQUIPMENT',
  DELETE_EQUIPMENT = 'DELETE_EQUIPMENT',
  UPDATE_RESULTS = 'UPDATE_RESULTS',
}

interface ZoneAction {
  type: ZoneActionType;
  payload?: any;
}

export const reducer = (state: Zone, action: ZoneAction) => {
  const { type, payload } = action;
  switch (type) {
    // ZONE

    case ZoneActionType.UPDATE_INFO: {
      const { key, value } = payload;
      return {
        ...state,
        [key]: value,
      };
    }
    case ZoneActionType.UPDATE_BOOLEAN: {
      const { key, value } = payload;
      return {
        ...state,
        [key]: value,
      };
    }

    // PHASES

    case ZoneActionType.ADD_PHASE: {
      const { phase } = payload;
      const id = getNewId(state.phases.map((phase: Phase) => phase.id));
      const phasesColor = state.phases.map((phase: Phase) => phase.color);
      const newPhase: Phase = {
        ...phase,
        id,
        duration: getPhaseDuration(phase, state.simulationType),
        color: getPhaseColor(phasesColor),
      };
      const phases = [...state.phases, newPhase];
      const sortedPhases = getSortedPhases(phases);
      return {
        ...state,
        phases: sortedPhases,
      };
    }
    case ZoneActionType.EDIT_PHASE: {
      const { phase } = payload;
      const newPhases: Phase[] = [...state.phases];
      const newDuration = getPhaseDuration(phase, state.simulationType);
      const newPhase: Phase = {
        ...phase,
        duration: newDuration,
      };
      const index = newPhases.findIndex((p: Phase) => p.id === phase.id);
      newPhases[index] = newPhase;
      const sortedPhases = getSortedPhases(newPhases);
      const updatedZone = getZoneData({
        ...state,
        phases: sortedPhases,
      });
      return updatedZone;
    }
    case ZoneActionType.DELETE_PHASE: {
      const { phase } = payload;
      let newPhases = [...state.phases];
      const index = state.phases.findIndex((p: Phase) => p.id === phase.id);

      ////////////
      //  HANDLE DELETE PHASE ID IN EQUIPMENTS
      ////////////

      if (index > -1) newPhases.splice(index, 1);
      const updatedZone = getZoneData({
        ...state,
        phases: newPhases,
      });
      return updatedZone;
    }

    // EQUIPMENT

    case ZoneActionType.ADD_EQUIPMENT: {
      const { equipmentData, equipmentType } = payload;
      let equipments: Equipment[] = [
        ...state.equipment[equipmentType as keyof EquipmentList],
      ];
      const id = getNewId(
        equipments.map((equipment: Equipment) => equipment.id),
      );
      const equipment: Equipment = {
        id,
        type: equipmentType,
        data: { ...equipmentData },
        quantity: 1,
        phases: state.phases.map((phase) => phase.id),
        duration: 0,
        consumption: 0,
        power: 0,
      } as Equipment;
      if (equipment.type === 'video') {
        equipment.consumptionType = 'Moyenne';
      }
      if (equipment.type === 'amp') {
        equipment.speakers = Array.from(
          { length: equipmentData.nbOutput },
          () => [],
        );
      }
      equipment.power = getEquipmentPower(equipment);
      equipments.push(equipment);

      const updatedZone = getZoneData({
        ...state,
        equipment: {
          ...state.equipment,
          [equipmentType]: equipments,
        },
      });
      return updatedZone;
    }
    case ZoneActionType.EDIT_EQUIPMENT: {
      const { equipment, equipmentType } = payload;
      let equipments: Equipment[] = [
        ...state.equipment[equipmentType as keyof EquipmentList],
      ];
      const index = equipments.findIndex(
        (m: Equipment) => m.id === equipment.id,
      );
      const newEquipment: Equipment = {
        ...equipment,
        power: getEquipmentPower(equipment),
      };

      equipments[index] = newEquipment;
      const updatedZone = getZoneData({
        ...state,
        equipment: {
          ...state.equipment,
          [equipmentType]: equipments,
        },
      });
      return updatedZone;
    }
    case ZoneActionType.DELETE_EQUIPMENT: {
      const { equipmentId, equipmentType } = payload;
      let newEquipments: Equipment[] = [
        ...state.equipment[equipmentType as keyof EquipmentList],
      ];
      const index = newEquipments.findIndex(
        (m: Equipment) => m.id === equipmentId,
      );
      if (index > -1) newEquipments.splice(index, 1);
      const updatedZone = getZoneData({
        ...state,
        equipment: {
          ...state.equipment,
          [equipmentType]: newEquipments,
        },
      });
      return updatedZone;
    }
    case ZoneActionType.UPDATE_RESULTS: {
      const { zone } = payload;
      return {
        ...state,
        resultsSimple: zone.resultsSimple,
        resultsComplete: zone.resultsComplete,
      };
    }
    default:
      return state;
  }
};

const getNewId = (ids: string[]) => {
  const existingIds = new Set(ids);
  const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let newId: string;
  do {
    newId = '';
    for (let i = 0; i < 4; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      newId += characters[randomIndex];
    }
  } while (existingIds.has(newId));
  return newId;
};

const getSortedPhases = (phases: Phase[]) => {
  return [...phases].sort((a, b) => {
    const dateDiff =
      new Date(a.dateStart).getTime() - new Date(b.dateStart).getTime();
    if (dateDiff !== 0) {
      return dateDiff;
    }
    return (
      parseInt(a.hourStart.replace('h', '.')) -
      parseInt(b.hourStart.replace('h', '.'))
    );
  });
};
