import styled from '@emotion/styled';
import Edit from '@mui/icons-material/Edit';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
} from '@mui/material';

import ColorizeIcon from '@mui/icons-material/Colorize';

import { categoryIcons } from '../../project/Nav';

import { SketchPicker } from 'react-color';
import { ColorResult } from 'react-color';

import ZoneAlert from './ZoneAlert';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import { Zone } from '@showatt/core';
import { useState } from 'react';
import {
  electricitySources,
  zoneIcons,
  zoneTypes,
} from './config';
import useZone from './context/useZone';

const Container = styled('div')`
  padding: 16px;
  display: flex;
  flex: 1;
  align-items: center;
  background-color: white;
  justify-content: space-between;
  gap: 16px;
  > * {
    display: flex;
    align-items: center;
    gap: 16px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 32px;
  width: 32px;
  display: flex;
`;

const ZoneHeader = ({
  zone,
  projectName,
}: {
  zone: Zone;
  projectName: string;
}) => {
  const { type, electricitySource, toCompute, name, color } = zone;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [editedName, setEditedName] = useState(name);
  const [editedColor, setEditedColor] = useState(color);

  const { updateZoneInfo, updateZoneBoolean } = useZone();

  // const { zoneId } = useParams();
  // const zoneIndex = parseInt(zoneId?.split('_')[1] || '0');

  // NAME EDIT
  const handleEditClick = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setEditedName(name);
  };

  const handleSaveClick = () => {
    if (editedName !== name) updateZoneInfo('name', editedName);
    if (editedColor !== color) updateZoneInfo('color', editedColor);
    setIsOpen(false);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedName(event.target.value);
  };

  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedColor(event.target.value);
  };

  const handleColorChangePicker = (color: ColorResult) => {
    setEditedColor(color.hex);
  };

  const handleColorPickerOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleColorPickerClose = () => {
    setAnchorEl(null);
  };

  const openPicker = Boolean(anchorEl);

  // SELECT & SWITCH EDIT

  const handleChange = (event: SelectChangeEvent, key: string) => {
    updateZoneInfo(key, event.target.value as string);
  };
  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateZoneBoolean('toCompute', event.target.checked);
  };

  const zoneIcon = zoneIcons[zone.type];
  const icon = categoryIcons[zoneIcon];

  return (
    <Container>
      <Dialog open={isOpen} onClose={handleCloseModal}>
        <DialogTitle id="alert-dialog-title">
          Edition de la zone
        </DialogTitle>
        <DialogContent sx={{display: "flex", flexDirection: "column", gap: "24px"}}>
          <TextField
            label="Nom de la zone"
            value={editedName}
            onChange={handleNameChange}
          />
          <TextField
            label="Couleur"
            value={editedColor}
            onChange={handleColorChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleColorPickerOpen}>
                    <ColorizeIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCloseModal}
          >
            Annuler
          </Button>
          <Button variant="contained" color="primary" onClick={handleSaveClick}>
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
      <Popover
        open={openPicker}
        onClose={handleColorPickerClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <SketchPicker
          color={editedColor}
          onChangeComplete={handleColorChangePicker}
        />
        <div style={{ textAlign: 'center', padding: '8px' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleColorPickerClose}
          >
            Confirm
          </Button>
        </div>
      </Popover>
      <div
        style={{
          backgroundColor: zone.color,
          padding: '6px 12px',
          cursor: 'pointer',
        }}
        onClick={handleEditClick}
      >
        <IconContainer>{icon}</IconContainer>
        <p className="h3b">{name}</p>
        <IconButton onClick={handleEditClick}>
          <Edit />
        </IconButton>
      </div>

      <ZoneAlert zone={zone}/>

      <div>
        <FormControl>
          <InputLabel id="demo-simple-select-label">Type de zone</InputLabel>
          <Select
            value={type}
            label="Type de zone"
            onChange={(e) => handleChange(e, 'type')}
            style={{ height: '45px' }}
          >
            {zoneTypes.map((zType) => (
              <MenuItem value={zType}>{zType}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="demo-simple-select-label">
            Source d'éléctricité
          </InputLabel>
          <Select
            value={electricitySource}
            label="Source d'éléctricité"
            onChange={(e) => handleChange(e, 'electricitySource')}
            style={{ height: '45px' }}
          >
            {electricitySources.map((source) => (
              <MenuItem value={source}>{source}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel
          control={<Switch checked={toCompute} onChange={handleChangeSwitch} />}
          label={
            <div style={{ whiteSpace: 'pre-wrap', lineHeight: '1.2' }}>
              Intégrer au total
              <br />
              de "{projectName}""
            </div>
          }
        />
      </div>
    </Container>
  );
};

export default ZoneHeader;
