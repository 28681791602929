import styled from '@emotion/styled';
import { Nav, NavElement } from '@showatt/core';
import { categoryIcons } from '../project/Nav';
import { zoneIcons } from '../show/zone/config';
import { SortButton } from '../ui/Button';
import { scopeIcons } from './ResultsSimple';

const Container = styled.div`
  margin: 32px 0 0;
  width: 100%;
  display: flex;
  gap: 24px;
  justify-content: center;
  align-items: center;
`;

const NavComponent = ({ result }: { result: Nav }) => {
  if (result.data.length === 0) return null;
  return (
    <Container>
      {result.data.map((element: NavElement) => (
        <NavElementComponent key={element.title} element={element} />
      ))}
    </Container>
  );
};

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  flex-shrink: 1;
`;

const NavElementComponent = ({ element }: { element: NavElement }) => {
  const { link, title, icon } = element;
  let elementIcon = undefined;
  if (icon in zoneIcons) {
    const zoneIcon = zoneIcons[icon];
    elementIcon = categoryIcons[zoneIcon];
  } else {
    elementIcon = scopeIcons[icon];
  }
  return (
    <a href={`#results_${link}`} style={{ textDecoration: 'none' }}>
      <SortButton
        startIcon={elementIcon && <IconContainer>{elementIcon}</IconContainer>}
        className={'result'}
      >
        {title}
      </SortButton>
    </a>
  );
};

export default NavComponent;
