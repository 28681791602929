import styled from '@emotion/styled';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Chip,
  createFilterOptions,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material';
import { Parameter, ParameterInput, Value } from '@showatt/core';
import { useEffect, useRef, useState } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CreateProjectModal from '../../dashboard/blocks/model/CreateProjectModal';
import OriginalBlock from '../../ui/Block';
import Button from '../../ui/Button';
import { ReactComponent as Building } from '../../ui/icons/categories/batiment.svg';
import { ReactComponent as DefaultIcon } from '../../ui/icons/categories/infos générales.svg';
import useBackTo from '../context/useBackTo';
import useModels from '../context/useModels';
import { useProject } from '../context/useProject';
import useProjects from '../context/useProjects';
import { ScrollHandle } from '../Project';

interface ModelOptionType {
  inputValue?: string;
  id: string;
  label: string;
  value: number;
  public: boolean;
}

const filter = createFilterOptions<ModelOptionType>();

const modelTypesIcons: { [key: string]: JSX.Element } = {
  building: <Building />,
};

interface ModelReferenceMultiSelectProps {
  parameter: Parameter;
  onUpdateParameter: (index: number, value: Value) => void;
  children?: React.ReactNode;
}

interface CreateInvitationProps {
  modelName: string;
  description: string;
  icon: JSX.Element;
  onClick: () => void;
  parameter: Parameter;
  children?: React.ReactNode;
}

const Block = styled(OriginalBlock)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;

  p {
    margin: 8px 0;
  }
  .title {
    font-weight: 600;
  }
  .left svg {
    width: 100px;
    height: 100px;
  }
`;

const CreateInvitation = ({
  modelName,
  description,
  icon,
  onClick,
  parameter,
  children,
}: CreateInvitationProps) => {
  return (
    <>
      <p className="hxr">{description}</p>
      <p className="hxr">
        Vous n'avez pas encore de modèle {modelName} sur SHOWATT.
      </p>
      <p className="hxc" style={{ marginBottom: '-12px' }}>
        Créez un {modelName.toLowerCase()} pour renseigner &laquo;
        {parameter.name}&raquo;
      </p>
      {children}
      <div
        style={{
          marginTop: '16px',
        }}
      >
        <Button variant="contained" color="primary" onClick={onClick}>
          Créer un {modelName}
        </Button>
      </div>
    </>
  );
};

const publicThenLabel = (a: ModelOptionType, b: ModelOptionType) =>
  +b.public - +a.public || a.label.localeCompare(b.label);

const ModelReferenceMultiSelect = ({
  parameter,
  onUpdateParameter,
  children,
}: ModelReferenceMultiSelectProps) => {
  const projectType = parameter.modelReference?.split('.')[0];
  const resultCode = parameter.modelReference?.split('.')[1];
  const scrollHandle = useRef<HTMLDivElement>(null);
  const [openProjectCreation, setOpenProjectCreation] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { projects } = useProjects({ projectType, includePublic: true });
  const { models } = useModels();
  const { projectId } = useParams();
  const { project, updateReferences } = useProject(projectId);
  const { state: backToState, setState: setBackToState } = useBackTo();

  const options: ModelOptionType[] = (projects?.filter((p) => !!p) || [])
    .map((project) => {
      const result: any = project.results.find(
        (r: any) => r.code && r.code === resultCode,
      );
      return {
        id: project._id.toString(),
        label: project.name,
        value: result.number,
        public: project.public,
        result: result ? `${result.number} ${result.unit}` : '',
      };
    })
    .sort(publicThenLabel);

  const defaultReference = (project?.references || []).find(
    (ref) => ref.index === parameter.index,
  );

  let defaultValue: any[] = [];
  if (defaultReference) {
    defaultValue = defaultReference.value
      .map((id: any) => {
        return options.find((p) => p.id === id);
      })
      .filter((p) => !!p);
  }

  const handleChange = (event: any, values: ModelOptionType[]) => {
    const addNewValue = values.find((v) => !v.id);
    if (addNewValue) {
      setOpenProjectCreation(true);
      return;
    }
    const total = values.length
      ? values.reduce((sum: number, project: any) => {
          return sum + project.value;
        }, 0)
      : undefined;
    onUpdateParameter(parameter.index, total);
    updateReferences([
      { index: parameter.index, value: values.map((p: any) => p.id) },
    ]);
  };

  useEffect(() => {
    if (
      scrollHandle.current &&
      backToState &&
      backToState.pathname === location.pathname &&
      backToState.index === parameter.index
    ) {
      const value = options.find((o) => o.id === backToState.value);
      if (!value) {
        return;
      }
      const values = [...defaultValue, value].filter((p) => !!p);
      const total = values.length
        ? values.reduce((sum: number, project: any) => {
            return sum + project.value;
          }, 0)
        : undefined;
      onUpdateParameter(parameter.index, total);
      updateReferences([
        { index: parameter.index, value: values.map((p: any) => p.id) },
      ]);
      setBackToState(null);
      setTimeout(() => {
        scrollHandle.current?.scrollIntoView();
      }, 50);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backToState, projects, scrollHandle.current]);

  if (!projectType || !resultCode || !models) {
    return null;
  }

  const icon = modelTypesIcons[projectType] || <DefaultIcon />;
  const referencedModel = models.find((m) => m.type === projectType);
  if (!referencedModel) {
    throw new Error(`Could not find referenced model ${projectType}`);
  }
  const modelName = referencedModel.name || 'modèle';

  const handleSave = (values: ParameterInput[], modelType: string) => {
    setBackToState({
      pathname: location.pathname,
      name: project?.name || 'mon projet',
      index: parameter.index,
    });
    navigate(`/project?values=${JSON.stringify(values)}&type=${modelType}`);
  };

  return (
    <>
      <CreateProjectModal
        open={openProjectCreation}
        setOpen={setOpenProjectCreation}
        onSave={handleSave}
        model={referencedModel}
      />

      <Block accent>
        <div className="left">{icon}</div>
        <div className="right">
          {!projects?.length && (
            <CreateInvitation
              modelName={modelName.toLowerCase()}
              description={referencedModel.description}
              icon={icon}
              onClick={() => {
                setOpenProjectCreation(true);
              }}
              parameter={parameter}
            >
              {children}
            </CreateInvitation>
          )}

          {!!projects?.length && (
            <>
              <ScrollHandle ref={scrollHandle}></ScrollHandle>
              <p className="hxr">{referencedModel.description}</p>
              <p className="hxc">
                Sélectionner votre {modelName.toLowerCase()} pour renseigner
                &laquo;{parameter.name}&raquo;
              </p>
              <Autocomplete
                multiple
                options={options}
                defaultValue={defaultValue}
                value={defaultValue}
                filterSelectedOptions
                limitTags={3}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      '& > svg': { mr: 2, flexShrink: 0 },
                      '& > span': { flexGrow: 1 },
                    }}
                    {...props}
                  >
                    {!!option.public && (
                      <Tooltip title="Modèle SHOWATT par défaut">
                        <BookmarksIcon style={{ fill: 'var(--yellow)' }} />
                      </Tooltip>
                    )}
                    <span>{option.label}</span>
                    {!!option.result && <Chip label={option.result} />}
                  </Box>
                )}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  filtered.push({
                    inputValue: params.inputValue,
                    label: `Créer un nouveau ${modelName.toLowerCase()} ${
                      params.inputValue
                    }`,
                    id: '',
                    value: 0,
                    public: false,
                  });

                  return filtered;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: [
                        <InputAdornment position="start" key={1}>
                          {icon}
                        </InputAdornment>,
                        params.InputProps.startAdornment,
                      ],
                    }}
                  />
                )}
                onChange={handleChange}
              />
              {children}
            </>
          )}
        </div>
      </Block>
    </>
  );
};

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <Alert severity="error">
      <AlertTitle>Problème de paramétrage</AlertTitle>
      {error.message}
    </Alert>
  );
};

const SafeParameterFactory = (props: ModelReferenceMultiSelectProps) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <ModelReferenceMultiSelect {...props} />
  </ErrorBoundary>
);

export default SafeParameterFactory;
