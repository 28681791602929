import { EquipmentDBProps } from '@showatt/core';
import { authenticatedFetch } from '../../authentication/authenticatedFetch';

const getEquipments = async (): Promise<EquipmentDBProps> => {
  const response = await authenticatedFetch(
    `${process.env.REACT_APP_API_URL}/equipments/`,
  );
  return await response.json();
};

export default getEquipments;
