import { Zone, Zones } from '@showatt/core';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  getProjectParameter,
  useProject,
} from '../../project/context/useProject';
import useZone, { Provider as ZoneProvider } from '../zone/context/useZone';
import getZoneComplete from './getZoneComplete';

import { PageContainer } from '../../ui/Container';

import { useEffect, useState } from 'react';
import getEquipments from '../zone/getEquipments';

import { ResultsContent } from '../../results/Results';

const ZoneCompleteResults = () => {
  const [zoneComplete, setZoneComplete] = useState<Zone | undefined>(undefined);
  const { zone } = useZone();
  const { data: db } = useQuery('equipments', () => getEquipments());

  useEffect(() => {
    if (zone && db) {
      const zoneCompleteTemp = getZoneComplete(zone, db);
      setZoneComplete(zoneCompleteTemp);
    }
  }, [zone, db]);

  if (!zone || !db || !zoneComplete || !zoneComplete.resultsComplete)
    return <p>Loading...</p>;

  return (
    <PageContainer noGap>
      <ResultsContent results={zoneComplete.resultsComplete} />
    </PageContainer>
  );
};

const ZoneCompleteResultsWithProvider = () => {
  const { projectId, zoneId } = useParams();
  const { project, updateZones } = useProject(projectId);
  if (!project || !zoneId) {
    return null;
  }
  const handleOnZoneChanged = (zone: Zone) => {
    if (JSON.stringify(project?.zones[zoneId]) !== JSON.stringify(zone)) {
      let zones: Zones = { ...project.zones };
      zones[zoneId] = zone;
      updateZones(zones);
    }
  };
  const simulationType =
    getProjectParameter(project, 'Simulation_type')?.value?.toString() || '';
  return (
    <ZoneProvider
      value={zoneId in project.zones ? project?.zones[zoneId] : undefined}
      onChange={handleOnZoneChanged}
      zoneId={zoneId}
      simulationType={simulationType}
    >
      <ZoneCompleteResults />
    </ZoneProvider>
  );
};

export default ZoneCompleteResultsWithProvider;
