import styled from '@emotion/styled';

import { Bar1D, Bar1DBar } from '@showatt/core';
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
} from 'recharts';

import {
  ValueType,
  NameType,
} from 'recharts/types/component/DefaultTooltipContent';

import {
  SeedsTooltipComponent,
  SeedsTooltipRow,
  SimpleTooltipRow,
} from './SeedsTooltipComponent';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const NoDataContainer = styled.div`
  width: 100%;
  padding: 16px;
  height: 100%;
  border: 1px dashed rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    text-align: center;
  }
`;

const Bar1DComponent = ({ result }: { result: Bar1D }) => {
  if (!result?.data?.length || result?.data?.length === 0) {
    return (
      <NoDataContainer>
        <p className="hxb">Aucune donnée</p>
        <p className="hxr">
          Le graphique sera affiché dès que vous aurez répondu aux premières
          questions associées
        </p>
      </NoDataContainer>
    );
  }
  return (
    <Container>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={result.data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="name" />
          {/* <YAxis /> */}
          <Tooltip content={<CustomTooltipComponent />} />
          {(result.bars || []).map((bar: Bar1DBar) => (
            <Bar
              key={bar.name}
              dataKey={bar.name}
              unit={result.unit}
              stackId="a"
              fill={bar.color as string}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </Container>
  );
};

const CustomTooltipComponent = ({
  active,
  payload,
}: TooltipProps<ValueType, NameType>): JSX.Element | null => {
  if (active && payload && payload.length > 0) {
    const reversedData = [...payload].reverse();
    return (
      <SeedsTooltipComponent>
        {reversedData.map((d: any) => (
          <SeedsTooltipRow
            key={d.name}
            color={d.color}
            category={d.name}
            value={d.value}
            unit={d.unit}
          />
        ))}
      </SeedsTooltipComponent>
    );
  }
  return null;
};

const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  > div {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}
`;

export const Bar1DLegend = ({ result }: { result: Bar1D }) => {
  const { bars, data } = result;
  const unit = result.unit;
  const reversedBars = bars ? [...bars].reverse() : [];
  if (data?.length && data?.length > 1) {
    return (
      <LegendContainer>
        <p className="h5r underline">Légende</p>
        <div>
          {reversedBars?.map((row: Bar1DBar) => (
            <>
              {
                <SimpleTooltipRow
                  key={row.name}
                  color={row.color}
                  category={row.name}
                  unit={unit}
                  //   link={row.link}
                />
              }
            </>
          ))}
        </div>
      </LegendContainer>
    );
  } else {
    let total: number = 0;
    const row = data![0];
    for (const key in row) {
      if (typeof row[key] === 'number' && key !== 'name') {
        total += row[key] as number;
      }
    }
    return (
      <LegendContainer>
        <p className="h6r underline">Légende</p>
        <div>
          {reversedBars?.map((bar: Bar1DBar) => (
            <>
              {
                <SeedsTooltipRow
                  key={bar.name}
                  color={bar.color}
                  category={bar.name}
                  value={data![0][bar.name].toString() || ''}
                  unit={unit}
                  percent={Number(data![0][bar.name]) / total}
                  // link={row.link}
                />
              }
            </>
          ))}
        </div>
      </LegendContainer>
    );
  }
};

export default Bar1DComponent;
