import styled from '@emotion/styled';
import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import SubdirectoryArrowRightOutlinedIcon from '@mui/icons-material/SubdirectoryArrowRightOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import { IconButton, Tooltip } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Equipment, EquipmentList } from '@showatt/core';
import { useState } from 'react';
import {
  electricitySourcesData,
  electricitySourceType,
  equipmentNames,
  substitutionConditions,
  substitutionConditionType,
} from '../zone/config';
import EquipmentFeedbackButton from '../zone/EquipmentFeedbackButton';
import { getMaxReduction } from './getZonesResults';

const ZoneRecommandations = ({
  equipment,
  electricitySource,
  zoneConsumption,
}: {
  equipment: EquipmentList;
  electricitySource: string;
  zoneConsumption: number;
}) => {
  return (
    <>
      {equipment &&
        Object.keys(equipment).map((equipmentType) => (
          <EquipmentRecommandations
            equipments={equipment[equipmentType as keyof EquipmentList]}
            equipmentType={equipmentType}
            electricitySource={electricitySource}
            zoneConsumption={zoneConsumption}
          />
        ))}
    </>
  );
};

const EquipmentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  overflow: hidden;
`;

const EquipmentRecommandations = ({
  equipments,
  equipmentType,
  electricitySource,
  zoneConsumption,
}: {
  equipments: Equipment[];
  equipmentType: string;
  electricitySource: string;
  zoneConsumption: number;
}) => {
  if (equipments.length === 0) return null;
  const maxReduction = getMaxReduction(equipments);
  const emissionReduction =
    maxReduction *
    electricitySourcesData[electricitySource as keyof electricitySourceType].FE;
  const equipmentReduction =
    maxReduction > 0 ? Math.round((maxReduction / zoneConsumption) * 100) : 0;
  return (
    <EquipmentContainer>
      <h3>{equipmentNames[equipmentType]}</h3>
      <p>
        Réductions potentielles de la consommation et des émissions de la zone :{' '}
        {equipmentReduction}%
      </p>
      <p>
        Réduction consommation potentielle :{' '}
        {Math.round(maxReduction).toLocaleString()} Wh
      </p>
      <p>
        Réduction émissions potentielle :{' '}
        {Math.round(emissionReduction).toLocaleString()} gCO2eq
      </p>
      <Rules equipmentType={equipmentType} />
      <EquipmentRecoTable
        equipments={equipments}
        equipmentType={equipmentType}
      />
    </EquipmentContainer>
  );
};

const RulesContainer = styled('div')`
  width: 100%;
  background-color: var(--color-primary-very-light);
  padding: 16px;
  > div:first-of-type {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
`;

const Rules = ({ equipmentType }: { equipmentType: string }) => {
  const [expanded, setExpanded] = useState(true);
  const conditions: substitutionConditionType[] =
    substitutionConditions[equipmentType];
  const handleCollapse = () => {
    setExpanded(false);
  };

  const handleExpand = () => {
    setExpanded(true);
  };
  return (
    <RulesContainer>
      <div>
        <p className="h6b">Règles suivies pour les recommandations</p>
        <IconButton
          sx={{ padding: 0 }}
          onClick={expanded ? handleCollapse : handleExpand}
        >
          {expanded ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
        </IconButton>
      </div>
      {expanded && (
        <p className="hxr" style={{ margin: '8px 0' }}>
          Nous vous proposons des pistes pour réduire la consommation électrique
          de votre installation scénique : un algorithme se charge de comparer
          entre elles les données techniques de chaque référence de matériel
          pour vous suggérer des matériels moins énergivores et au rendu
          audiovisuel équivalent. Pour ce faire, nous avons défini des seuils de
          tolérance pour chaque paramètre technique :
        </p>
      )}
      {expanded && (
        <>
          {conditions.map((condition) => (
            <RuleContainer className="hxr">{getRule(condition)}</RuleContainer>
          ))}
        </>
      )}
    </RulesContainer>
  );
};

const RuleContainer = styled('p')`
  width: 100%;
  margin-top: 8px;
`;

const getRule = (condition: substitutionConditionType) => {
  const { name_displayed, condition_type, tolerance, description } = condition;
  switch (condition_type) {
    case 'function':
      return (
        <>
        <span className="hxb underline">{name_displayed}</span> : {description}
        </>
      );
    case 'inferior':
      return (
        <>
          <span className="hxb underline">{name_displayed}</span> : doit être
          inférieur.e au matériel configuré
        </>
      );
    case 'equal':
      return (
        <>
          <span className="hxb underline">{name_displayed}</span> : doit être
          identique au matériel configuré
        </>
      );
    case 'superior':
      return (
        <>
          <span className="hxb underline">{name_displayed}</span> : doit être
          supérieur.e au matériel configuré
        </>
      );
    case 'include':
      return (
        <>
          <span className="hxb underline">{name_displayed}</span> : doit
          proposer au moins les mêmes
        </>
      );
    case 'superior or equal':
      return (
        <>
          <span className="hxb underline">{name_displayed}</span> : doit être
          supérieur.e ou égal.e au matériel configuré
        </>
      );
    case 'tolerance':
      if (tolerance) {
        return (
          <>
            <span className="hxb underline">{name_displayed}</span> :{' '}
            {`doit être égal.e à + ou - ${
              Math.round(tolerance * 1000) / 10
            }% du matériel configuré`}
          </>
        );
      }
  }
  return null;
};

const Container = styled('div')`
  width: 100%;
  .row-substitute {
    background-color: var(--color-primary-very-light);
  }
  .row-equipment-to-replace {
    background-color: var(--color-secondary-very-light);
  }
  .row-equipment-ok {
    background-color: var(--color-primary-light);
  }
`;

const EquipmentRecoTable = ({
  equipments,
  equipmentType,
}: {
  equipments: Equipment[];
  equipmentType: string;
}) => {
  if (equipments.length === 0) return null;

  const rows = getRows(equipments, equipmentType);
  const maxImpact = Math.max(...rows.map((row: any) => getImpactConso(row)));
  const columns = getColumns(equipmentType, maxImpact);

  if (rows.length === 0) return null;

  const rowClassGetter = (params: any) => {
    return params.row.isSubstitute
      ? 'row-substitute'
      : params.row.substitutes?.length === 0
      ? 'row-equipment-ok'
      : 'row-equipment-to-replace';
  };

  return (
    <Container>
      <DataGrid
        density="compact"
        columns={columns}
        rows={rows}
        getRowClassName={rowClassGetter}
        hideFooter={true}
      />
    </Container>
  );
};

const getRows = (equipments: Equipment[], equipmentType: string) => {
  let rows: Equipment[] = [];
  let index = 0;
  equipments.forEach((equipment) => {
    if (equipment.type === equipmentType) {
      rows.push({
        ...equipment,
        id: index.toString(),
      });
      index++;
      equipment.substitutes?.forEach((substitute) => {
        rows.push({
          ...substitute,
          id: index.toString(),
        });
        index++;
      });
    }
  });
  return rows;
};

const getColumns = (equipmentType: string, maxImpact: number): GridColDef[] => {
  let columns: GridColDef[] = [
    {
      field: 'ref',
      headerName: 'Réference',
      width: 150,
      renderCell: (params: any) => {
        const row = params.row;
        if (!row) return null;

        return (
          <Tooltip title={getRowTooltip(row)} arrow>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {row.isSubstitute && (
                <SubdirectoryArrowRightOutlinedIcon
                  style={{ marginRight: '8px', width: '18px', height: '18px' }}
                />
              )}
              {!row.isSubstitute && row.substitutes.length === 0 && (
                <TaskAltOutlinedIcon
                  style={{ marginRight: '8px', width: '18px', height: '18px' }}
                />
              )}
              {row.data.ref}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'feedback',
      headerName: 'Feedbacks',
      width: 200,
      renderCell: (params: any) => {
        const row = params.row;
        if (!row) return null;
        return (
          <Tooltip title="Envoyer un message concernant ce matériel, par exemple pour signaler une erreur à propos de ses caractéristiques">
            <EquipmentFeedbackButton equipment={row} />
          </Tooltip>
        );
      },
    },
  ];

  const getRowTooltip = (row: any) => {
    if (row.isSubstitute) {
      return 'Matériel de substitution suggéré';
    } else if (row.substitutes.length === 0) {
      return 'Matériel configuré dans la zone, optimisé en terme énergétique';
    } else {
      return `Matériel configuré dans la zone, pour lequel ${row.substitutes.length} matériel(s) sont moins consommateurs`;
    }
  };

  const consoColumn = {
    field: 'conso',
    headerName: 'Conso',
    width: 150,
    valueGetter: getConso,
  };
  const impactColumn = {
    field: 'impact_conso',
    headerName: 'Impact Conso (Wh)',
    width: 150,
    valueGetter: getImpact,
    renderCell: (params: any) => {
      const row = params.row;
      if (!row) return null;
      const impact = getImpactConso(row);
      const diameter = (impact / maxImpact) * 24; // Calculate the diameter as a percentage of the maxImpact value
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: `${diameter}px`,
              height: `${diameter}px`,
              backgroundColor: 'var(--color-primary)',
              borderRadius: '50%',
              marginRight: '8px',
            }}
          />
          {params.value}
        </div>
      );
    },
  };

  const impactRelColumn = {
    field: 'impact_rel_conso',
    headerName: 'Impact Conso (%)',
    width: 150,
    valueGetter: getImpactRel,
  };

  if (equipmentType === 'light') {
    columns.push(
      ...[
        {
          field: 'marque',
          headerName: 'Marque',
          width: 150,
          valueGetter: (params: any) => params.row.data.marque,
        },
        {
          field: 'type',
          headerName: 'Type',
          width: 150,
          valueGetter: (params: any) => params.row.data.type,
        },
        consoColumn,
        impactColumn,
        impactRelColumn,
        {
          field: 'power',
          headerName: 'Power',
          width: 120,
        },
        {
          field: 'candlepower',
          headerName: 'Candlepower',
          width: 150,
          valueGetter: (params: any) => params.row.data.candlepower,
        },
        {
          field: 'zoom_range_min',
          headerName: 'Zoom Range (Min)',
          width: 180,
          valueGetter: (params: any) => params.row.data.zoomRangeMin,
        },
        {
          field: 'zoom_range_max',
          headerName: 'Zoom Range (Max)',
          width: 180,
          valueGetter: (params: any) => params.row.data.zoomRangeMax,
        },
        {
          field: 'temperature',
          headerName: 'Temperature',
          width: 150,
          valueGetter: (params: any) =>
            params.row.data.temperatureMin === params.row.data.temperatureMax
              ? params.row.data.temperatureMin
              : `${params.row.data.temperatureMin} - ${params.row.data.temperatureMax}`,
        },
      ],
    );
  } else if (equipmentType === 'rigg') {
    columns.push(
      ...[
        { field: 'marque', headerName: 'Marque', width: 150 },
        consoColumn,
        impactColumn,
        impactRelColumn,
        { field: 'power', headerName: 'Power', width: 120 },
        {
          field: 'charge',
          headerName: 'Charge',
          width: 150,
          valueGetter: (params: any) => params.row.data.charge,
        },
        {
          field: 'speed',
          headerName: 'Vitesse',
          width: 150,
          valueGetter: (params: any) => params.row.data.speed,
        },
      ],
    );
  } else if (equipmentType === 'video') {
    columns.push(
      ...[
        { field: 'marque', headerName: 'Marque', width: 150, valueGetter: (params: any) => params.row.data.marque, },
        consoColumn,
        impactColumn,
        impactRelColumn,
        { field: 'consom_moy', headerName: 'Consom. Moy.', width: 180, valueGetter: (params: any) => params.row.data.consom_moy },
        { field: 'consom_max', headerName: 'Consom. Max.', width: 180, valueGetter: (params: any) => params.row.data.consom_max },
      ],
    );
  } else if (equipmentType === 'prod') {
    columns.push(
      ...[
        { field: 'marque', headerName: 'Marque', width: 150, valueGetter: (params: any) => params.row.data.marque },
        consoColumn,
        impactColumn,
        impactRelColumn,
        { field: 'power', headerName: 'Puissance', width: 180, valueGetter: (params: any) => params.row.data.power },
      ],
    );
  } else if (equipmentType === 'amp') {
    columns.push(
      ...[
        { field: 'marque', headerName: 'Marque', width: 150, valueGetter: (params: any) => params.row.data.marque },
        consoColumn,
        impactColumn,
        impactRelColumn,
        { field: 'idlePower', headerName: 'Power Idle', width: 150, valueGetter: (params: any) => params.row.data.idlePower },
        { field: 'inputPowerMax', headerName: 'Power Max', width: 150, valueGetter: (params: any) => params.row.data.inputPowerMax },
        { field: 'nbOutput', headerName: 'Output Count', width: 150, valueGetter: (params: any) => params.row.data.nbOutput },
        { field: 'type', headerName: 'Type', width: 150, valueGetter: (params: any) => params.row.data.type },
      ],
    );
  } else if (equipmentType === 'speaker') {
    columns.push(
      ...[
        { field: 'marque', headerName: 'Marque', width: 150, valueGetter: (params: any) => params.row.data.marque },
        consoColumn,
        impactColumn,
        impactRelColumn,
        { field: 'impedence', headerName: 'Impedence', width: 150, valueGetter: (params: any) => params.row.data.impedence },
      ],
    );
  }
  return columns;
};

const getImpactConso = (row: any) => {
  return row.isSubstitute
    ? Math.round(row.consumptionOfReplaced - row.consumption)
    : 0;
};

const getConso = (params: any) => {
  const row = params.row;
  if (!row) return '';
  const { consumption } = row;
  return `${Math.round(consumption)} Wh`;
};

const getImpact = (params: any) => {
  const row = params.row;
  if (!row) return '';
  const { isSubstitute, consumption, consumptionOfReplaced } = row;
  if (isSubstitute)
    return `${Math.round(consumption - consumptionOfReplaced)} Wh`;
  return '0 Wh';
};

const getImpactRel = (params: any) => {
  const row = params.row;
  if (!row) return '';
  const { isSubstitute, consumption, consumptionOfReplaced } = row;

  if (isSubstitute)
    return `${Math.round(
      ((consumption - consumptionOfReplaced) / consumptionOfReplaced) * 100,
    )} %`;
  return '0 %';
};

export default ZoneRecommandations;
