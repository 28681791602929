import MessageIcon from '@mui/icons-material/Message';
import { FeedbackForm } from '../../authentication/Feedback';

import {
  Equipment,
  EquipmentData,
} from '@showatt/core';
import { useState } from 'react';
import { IconButton } from '@mui/material';

const EquipmentFeedbackButton = ({ equipment }: { equipment: Equipment }) => {
  const [openFeedback, setOpenFeedback] = useState(false);
  const handleCloseFeedback = () => setOpenFeedback(false);
  const object = `Feedbacks concernant l'équipement ${equipment.data.marque} ${equipment.data.ref}`;
  const message = getEquipmentFeedbackMsg(equipment.data);
  return (
    <>
      <FeedbackForm
        open={openFeedback}
        onClose={handleCloseFeedback}
        object={object}
        message={message}
        size="lg"
      />
      <IconButton
        onClick={() => setOpenFeedback(true)}
        onMouseDown={(e) => e.preventDefault()}
        size="small"
      >
        <MessageIcon />
      </IconButton>
    </>
  );
};

const getEquipmentFeedbackMsg = (equipmentData: EquipmentData) => {
  const excludedKeys = ['id', 'equipmentType', 'substitutesId'];
  const result: string[] = [];

  for (const key in equipmentData) {
    if (!excludedKeys.includes(key)) {
      const value = equipmentData[key as keyof EquipmentData];
      result.push(`${key}: ${value}`);
    }
  }
  return `Je souhaite vous partager une information concernant le matériel cité en objet et avec les caractéristiques suivantes : ${result.join(', ')}.\n\nMon message : `;
};

export default EquipmentFeedbackButton;
