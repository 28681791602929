import {
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@mui/material';

const RadioSelect = ({
  possibleValues,
  disabled,
  ...props
}: RadioGroupProps & { possibleValues: string[]; disabled: boolean }) => (
  <RadioGroup
    {...props}
    sx={{
      flexDirection: 'row',
      '.MuiFormControlLabel-label': {
        margin: '4px',
      },
    }}
  >
    {possibleValues.map((possibleValue) => (
      <FormControlLabel
        key={possibleValue}
        value={possibleValue}
        control={<Radio />}
        label={possibleValue}
        disabled={disabled}
      />
    ))}
  </RadioGroup>
);

export default RadioSelect;
