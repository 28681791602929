import styled from '@emotion/styled';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as Illu } from '../ui/energie.svg';

import Button from '../ui/Button';
import useConfiguration from '../useConfiguration';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 80px 0;
  align-items: center;
  position: relative;
`;

const Block = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: stretch;
  border-radius: 16px;
  width: 80%;
  position: relative;
  > div:first-of-type {
    width: 30%;
    height: 100%;
    background: var(--color-primary);
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    svg {
      max-width: 90%;
      max-height: 90%;
    }
  }
  > div:last-of-type {
    margin-left: 30%;
    width: 70%;
    padding: 32px;
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

const BottomNote = styled.p`
  font-weight: 12;
  font-style: italic;
  text-align: center;
  max-width: 800px;
  margin-top: 32px;
  top: 100%;
  margin-left: 0 !important;
`;

const Home = () => {
  const navigate = useNavigate();
  const { configuration } = useConfiguration();
  return (
    <Container>
      <Block>
        <div>
        <Illu />
        </div>
        
        <div>
          <MessageContainer
            dangerouslySetInnerHTML={{
              __html: configuration['home.welcomeMessage'],
            }}
          ></MessageContainer>
          <Button
            variant="contained"
            onClick={() => navigate(`/authentication/register`)}
            color="primary"
          >
            S'inscrire pour découvrir SHOWATT
          </Button>
          <Button
            variant="contained"
            onClick={() => navigate(`/authentication/login`)}
            color="secondary"
          >
            J'ai déjà un compte, me connecter
          </Button>
        </div>
      </Block>

      <BottomNote>
        <div
          dangerouslySetInnerHTML={{
            __html: configuration['home.bottomMessage'],
          }}
        ></div>
      </BottomNote>
    </Container>
  );
};

export default Home;
