import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { useTour } from '@reactour/tour';
import { Sector, Zones } from '@showatt/core';
import { Helmet } from 'react-helmet';
import { useLocation, useParams } from 'react-router-dom';

import CalculatorLayout from '../layout/CalculatorLayout';

import MostImpactfulActions from '../actions/card/MostImpactfulActions';
import ResultsSimple from '../results/ResultsSimple';
import { getProjectTitle, useProject } from './context/useProject';
import Nav from './Nav';
import Parameters from './Parameters';

import React, { useEffect, useState } from 'react';
import useOnboarding from '../onboarding/useOnboarding';
import BackToComponent from './BackToComponent';
import useBackTo from './context/useBackTo';
import { ReactComponent as BaseLoadingIllustration } from './images/water-crops.svg';

import { getHeaderColor } from '../show/zone/context/useZone';

interface SimulatorOptions {
  secondaryNav: boolean;
}

const simulatorOptions = {
  secondaryNav: false,
};

type SectorComponentWrapperProps = {
  depth: number;
};

const SectorComponentWrapper = styled.div`
  margin-bottom: ${(props: SectorComponentWrapperProps) =>
    props.depth === 0 ? '16px' : '0px'};
  background-color: white;
`;

const SectorWrapper = styled.div`
  width: 100%;
  background-color: white;
  padding: 0 16px;
`;

const LoadingContainer = styled.div`
  text-align: center;
  margin-top: 184px;
  button {
    margin-top: 40px;
  }
`;

const LoadingIllustration = styled(BaseLoadingIllustration)`
  width: 600px;
  filter: grayscale(95%) brightness(120%);
  opacity: 0.8;
`;

export const Loading = () => (
  <LoadingContainer>
    <LoadingIllustration />
    <p className="h2b">
      Préparation de votre environnement de travail en cours...
    </p>
  </LoadingContainer>
);

// NavContainer is used to display the margin between Nav and Parameters
const NavContainer = styled.div`
  background-color: white;
  border-radius: 8px 8px 0 0;
`;

export const ProjectForm = () => {
  const { projectId } = useParams();
  const { project, loading } = useProject(projectId);
  const { state: backToState, setState: setBackTo } = useBackTo();
  const location = useLocation();

  useEffect(() => {
    const scrollTo = (location.state as any)?.scrollTo;
    if (scrollTo) {
      const element = document.querySelector(scrollTo);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'auto' });
        }, 100);
      }
    }
  }, [location]);

  useEffect(() => {
    if (!projectId) return;
    if (backToState && !backToState?.value) {
      setBackTo({
        ...backToState,
        value: projectId,
      });
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  if (!project) return null;

  const projectTitle = getProjectTitle(project);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${projectTitle} - SHOWATT`}</title>
      </Helmet>
      <CalculatorLayout
        useLocalStorage={false}
        header={
          <NavContainer>
            {backToState && backToState.value === projectId && (
              <BackToComponent state={backToState} />
            )}
            <Nav />
          </NavContainer>
        }
        content={
          <SectorWrapper>
            <SectorComponent
              sectors={project?.sectors}
              depth={0}
              upperSector={null}
              options={simulatorOptions}
              zones={project.zones}
            />
          </SectorWrapper>
        }
        results={
          <>
            <ResultsSimple project={project} loading={loading} />
            {project.model.config.actions && (
              <MostImpactfulActions actions={project?.actions} />
            )}
          </>
        }
      />
    </>
  );
};

export const ScrollToTopOnce = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
};

export const StartTour = ({ tour }: { tour: string }) => {
  const { enabledTour } = useOnboarding();
  const { setIsOpen } = useTour();

  useEffect(() => {
    if (enabledTour === tour) {
      setTimeout(() => {
        setIsOpen(true);
      }, 350);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

const ScrollHandleContainer = styled.div`
  position: relative;
  top: -200px;
`;

export const ScrollHandle = React.forwardRef<HTMLDivElement, any>(
  (props, ref) => {
    const [top, setTop] = useState(0);

    useEffect(() => {
      const categoriesWrapper = document.querySelector('.categories_wrapper');
      if (!categoriesWrapper) return;
      const bottom = categoriesWrapper.getBoundingClientRect().bottom;
      setTop(bottom + (props.offset || 0) + 20);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <ScrollHandleContainer
        {...props}
        ref={ref}
        style={{ top: -top }}
      ></ScrollHandleContainer>
    );
  },
);

const SectorComponent = ({
  sectors,
  depth,
  upperSector,
  options,
  zones
}: {
  sectors?: Sector[];
  depth: number;
  upperSector: Sector | null;
  options: SimulatorOptions;
  zones: Zones;
}) => {
  const { projectId } = useParams();
  const { project, updateParameter } = useProject(projectId);

  if (!project) {
    return null;
  }

  const getSectorId = (
    sector: Sector,
    upperSector: Sector | null,
    depth: number,
  ) => {
    return upperSector && depth > 0
      ? `${upperSector.information.name}-${sector.information.name}-${depth}`.replace(
          /\W/g,
          '_',
        )
      : `${sector.information.name}-${depth}`.replace(/\W/g, '_');
  };

  return (
    <>
      {(sectors || []).map((sector, indexSector) => {
        const displayHeader =
          sector.parameters.length > 0 || sector.sectors.length > 0;
        const sectorId = getSectorId(sector, upperSector, depth);

        return (
          <SectorComponentWrapper
            key={`${sector.information.name}-${depth}`}
            depth={depth}
          >
            <ScrollHandle id={sectorId}></ScrollHandle>
            {displayHeader && (
              <SectorHeader
                upperSector={upperSector}
                sector={sector}
                depth={depth}
                information={sector.information}
                subsectors={sector.sectors}
                sectorId={sectorId}
                options={options}
                externalModules={
                  project.model.config.parameters.externalModules
                }
                zones={zones}
                indexSector={indexSector}
              />
            )}
            {sector.parameters.length > 0 && (
              <Parameters
                autofocusFirst={false}
                parameters={sector.parameters}
                onUpdateParameter={updateParameter}
              />
            )}
            {sector.sectors.length > 0 && (
              <SectorComponent
                sectors={sector.sectors}
                depth={depth + 1}
                upperSector={sector}
                options={options}
                zones={zones}
              />
            )}
          </SectorComponentWrapper>
        );
      })}
    </>
  );
};

type SectorHeaderWrapperProps = {
  backgroundColor: string;
};

export const SectorHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 16px;
  color: black;
  justify-content: space-between;
  &.level0 {
    top: 206px;
    position: sticky;
    padding: 14px 16px;
    border-radius: 4px;
    background-color: ${(props: SectorHeaderWrapperProps) =>
      props.backgroundColor || 'lightgrey'};
    z-index: 110;
  }
  &.level0 p {
    color: black;
  }
  &.level1 {
    width: 100%;
    padding: 30px 0 16px 20px;
    top: 267px;
    background-color: white;
    position: sticky;
    z-index: 100;
  }
  &.level1 p {
    line-height: 18px;
  }
  &.level1 p::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background-color: ${(props: SectorHeaderWrapperProps) =>
      props.backgroundColor || 'lightgrey'};
    left: 0;
    display: block;
    line-height: 18px;
    margin-top: 1px;
  }
  &.level2 p {
    font-style: italic;
  }
`;

const headerClassName: { [key: number]: string } = {
  0: 'h4b',
  1: 'h5b',
  2: 'hxb',
};

const CategoriesWrapper = styled.div`
  display: flex;
`;

interface SectorHeaderProps {
  sector: Sector;
  upperSector: Sector | null;
  depth: number;
  sectorId: string;
  information: {
    color: string;
    name: string;
  };
  subsectors: Sector[];
  options: SimulatorOptions;
  externalModules?: {
    [key: string]: string[];
  };
  zones: Zones;
  indexSector: number;
}

// https://stackoverflow.com/a/72595895/1665540
const contrastColor = (backgroundColor: string) => {
  try {
    return ['black', 'white'][
      ~~(
        [1, 3, 5]
          .map((p) => parseInt(backgroundColor.substr(p, 2), 16))
          .reduce((r, v, i) => [0.299, 0.587, 0.114][i] * v + r, 0) < 128
      )
    ];
  } catch (e) {
    return 'black';
  }
};

const SectorHeader = ({
  sector,
  upperSector,
  depth,
  information,
  sectorId,
  subsectors,
  options,
  externalModules,
  zones,
  indexSector
}: SectorHeaderProps) => {
  // if (depth > 0) return null;
  const { name } = information;

  const color = getHeaderColor(zones, name, indexSector)

  const navigation = subsectors.map((subsector) => ({
    name: subsector.information.name,
    key: `${sector.information.name}-${subsector.information.name}-${
      depth + 1
    }`,
    color: subsector.information.color,
  }));

  const handleButtonClicked = (hash: string) => () => {
    document?.getElementById(hash)?.scrollIntoView();
  };

  return (
    <>
      <SectorHeaderWrapper backgroundColor={color} className={`level${depth}`}>
        <p className={headerClassName[depth]}>{name}</p>
        {depth === 0 && options.secondaryNav && navigation.length > 0 && (
          <CategoriesWrapper className="categories_wrapper">
            {navigation.map(({ name, key, color }) => (
              <Button
                key={key}
                onClick={handleButtonClicked(key)}
                size="small"
                sx={{
                  '&:hover': {
                    bgcolor: color,
                    color: contrastColor(color || '#ffffff'),
                  },
                }}
              >
                {name}
              </Button>
            ))}
          </CategoriesWrapper>
        )}
      </SectorHeaderWrapper>
    </>
  );
};
