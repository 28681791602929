import styled from '@emotion/styled';
import { useState } from 'react';

import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import TextField from '../project/parameters/TextField';

import BaseBlock from '../ui/Block';

import { useLocation } from 'react-router-dom';

import { useAuthentication } from './context/useAuthentication';

import {
  Breakpoint,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import Button from '../ui/Button';

import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import Fab from '@mui/material/Fab';

const Block = styled(BaseBlock)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  width: 100%;
`;

const validationSchema = yup.object({
  object: yup.string().required('Champ obligatoire'),
  message: yup.string().required('Champ obligatoire'),
});

export const FeedbackForm = ({
  open,
  onClose,
  object,
  message,
  size,
}: {
  open: boolean;
  onClose: () => void;
  object?: string;
  message?: string;
  size?: Breakpoint;
}) => {
  const { auth, loading, sendMessage, error: authError } = useAuthentication();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const formik = useFormik({
    initialValues: {
      object: object ? object : '',
      message: message ? message : '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await sendMessage(values.object, values.message, location.pathname);
      enqueueSnackbar('Votre message a bien été envoyé', {
        variant: 'success',
      });
      onClose();
    },
  });
  if (!auth) return null;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      id="create_modal"
      fullWidth={true}
      maxWidth={size || 'sm'}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="scroll-dialog-title">Besoin d'aide ?</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            sx={{
              fontWeight: '400',
              fontSize: '16px',
              fontFamily: "'Montserrat', sans-serif",
              margin: '16px 0 32px',
            }}
          >
            {!!authError && <Block accent>{`${authError}`}</Block>}
            <p className="hxr">
              Pour la moindre question ou suggestion concernant SHOWATT,
              n'hésitez pas : <b>contactez-nous !</b> Nous vous répondrons dans
              les plus brefs, et néanmoins raisonnables, délais.
            </p>
            <br />
            <p className="hxr">
              Pour que nous puissions vous aider au mieux, merci de mentionner
              le nom de votre structure et celui de votre projet, et d'indiquer
              précisément le paramètre concerné par votre demande.
            </p>
          </DialogContentText>

          <TextField
            id="object"
            name="object"
            label="Objet"
            autoFocus
            value={formik.values.object}
            onChange={formik.handleChange}
            error={formik.touched.object && Boolean(formik.errors.object)}
            helperText={formik.touched.object && formik.errors.object}
            fullWidth
            sx={{ marginBottom: '16px' }}
          />

          <TextField
            id="message"
            name="message"
            label="Message"
            multiline
            value={formik.values.message.replace(/\\n/g, '\n')}
            onChange={formik.handleChange}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Annuler</Button>
          <Button type="submit" color="primary" disabled={loading}>
            Envoyer
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const Feedback = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <FeedbackForm open={open} onClose={handleClose} />
      <Fab
        sx={{
          position: 'fixed',
          right: '16px',
          bottom: '16px',
          backgroundColor: 'var(--bg-feedback)',
          color: 'var(--text-feedback)',
        }}
        onClick={() => setOpen(true)}
      >
        <QuestionMarkRoundedIcon />
      </Fab>
    </>
  );
};

export default Feedback;
