import styled from '@emotion/styled';
import { Text as TextType } from '@showatt/core';
import useConfiguration from '../useConfiguration';

const Container = styled.div`
  margin: 0 16px;
  width: 100%;
  padding: 16px;
  background-color: var(--color-primary-very-light);
  display: flex;
  flex-direction: column;
  gap: 12px;
  li { 
    margin-left: 40px;
  }
  }
`;

export const Text = ({ result }: { result: TextType }) => {
  const { configuration } = useConfiguration();
  return (
    <Container
      dangerouslySetInnerHTML={{
        __html: configuration[result.textId],
      }}
    />
  );
};

export default Text;
